import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faCamera, faLock, faPhone } from "@fortawesome/free-solid-svg-icons"; // Ensure correct import
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import "./Edit_Profile.css";
import { readFromDB, updateInDB } from "../../indexedDB"; // Import IndexedDB utility
import toast from "react-hot-toast";
import { useRef } from "react";
import { APIUpdate, updateImg } from "../../api/Profile";
import { APIGetImg } from "../../api/GetImg";
import { useNavigate } from "react-router-dom";

function EditProfile() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState([]);
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const phnoRef = useRef(); // Reference for email input

  //read from User
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const user = await readFromDB("UserStore", "user", { cache: "none" }); // Fetch user details from IndexedDB
        if (user) {
          setIsLoggedIn(true);
          setUser(user); // Assuming the user object has a "name" field
        } else {
          navigate("/Email");
          setIsLoggedIn(false);
        }
      } catch (error) {
        setIsLoggedIn(false);
      }
    };
    checkLoginStatus();
  }, []);

  //handle image
  async function handleImageChange(event) {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      await updateImg(user.id, file, (data) => {
        setLoading(false);
        if (data.message) {
          toast.success("Profile updated successfully!");
          APIGetImg(user.id, { cache: "none" }).then((data) => {
            if (data) {
              const updatedUser = { ...user, img: data.img };
              updateInDB("UserStore", "user", updatedUser);
              setUser(updatedUser);
              document.getElementById("profilePic").src = data.img;
            } else {
              toast.error("Error fetching updated image!");
            }
          });
        } else {
          toast.error("Error updating profile!");
        }
      });
    }
  }

  //Update name and phone number
  async function update() {
    const phno = phnoRef.current.value;
    setLoading(true);
    const data = await APIUpdate(user.id, phno);
    setLoading(false);
    if (phno == "") {
      toast.error("Please enter phone number!");
      return;
    }
    if (data.message) {
      const updatedUser = { ...user, phone: phno };
      await updateInDB("UserStore", "user", updatedUser);
      setChanged(false);

      toast.success("Profile updated successfully!");
    } else {
      toast.error("Error updating profile!");
    }
  }
  return (
    <>
      <Navbar />
      <div className="container-fluid px-0">
        {/* section 1 */}
        <div className="row ">
          <div className="col-10">
            <ol className="breadcrumb mb-3">
              <li className="breadcrumb-item">
                <Link id="link" to="/Home">
                  Home{" "}
                </Link>
              </li>

              <li className="breadcrumb-item">
                <Link id="link" to="/More">
                  More
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/EditProfile">
                  EditProfile
                </Link>
              </li>
            </ol>
          </div>
        </div>

        {/* section 2 */}
        <div className="d-flex align-items-center justify-content-center position-relative">
          <img
            id="profilePic"
            className="profile_pic"
            src={user.img}
            alt="Profile"
            loading="lazy"
          />
          <button
            className="edit-button"
            onClick={() => document.getElementById("imageInput").click()}
          >
            <FontAwesomeIcon icon={faCamera} />
          </button>
          <input
            id="imageInput"
            className="form-control"
            type="file"
            accept="image/*"
            onChange={(e) => {
              handleImageChange(e);
            }}
            style={{ display: "none" }}
          />
        </div>

        {/* section 3 */}
        <div>
          <div className="row Plist mt-1">
            <div className="Eicon col-2 d-flex align-items-center justify-content-center ">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className="col-10 d-flex flex-column">
              <label>User Name</label>
              <input
                type="text"
                className="Eprofile form-control p-2 shadow-none"
                defaultValue={user.username}
                readOnly
                disabled
              />
            </div>
          </div>
          <hr />

          <div className="row Plist ">
            <div className="Eicon col-2 d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className="col-10 d-flex flex-column">
              <label>Phone Number</label>
              <input
                type="number"
                className="Eprofile form-control p-2 shadow-none"
                placeholder="Enter phone number"
                ref={phnoRef}
                defaultValue={user.phone}
                onChange={() => {
                  setChanged(true);
                }}
              />
            </div>
          </div>
          <div className="OrderButton mt-3">
            <button
              className="btn btn-primary"
              onClick={() => update()}
              disabled={!changed}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
      <Toolbar />
      <div style={{ height: "60px" }}></div>
    </>
  );
}
export default EditProfile;
