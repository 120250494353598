import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Navbar/Navbar";
import Toolbar from "../ToolBar/Toolbar";
import "./order_detail.css";
import { APIOrderDetail } from "../../api/Order_detail";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { readFromDB } from "../../indexedDB";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { TbArrowBack } from "react-icons/tb";
import { APICancelOrder } from "../../api/CancelOrder";

function OrderDetail() {
  const [orderDetail, setOrderDetail] = useState([]);
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [order, setOrder] = useState([]);
  const [User, setUser] = useState([]);
  const recipt1 = document.getElementById("recipt");
  const recipt2 = document.getElementById("recipt2");
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("order_id");

  useEffect(() => {
    if (recipt1 && recipt2) {
      recipt1.style.display = "none";
      recipt2.style.display = "block";
    } else {
      console.error("Recipt elements are null");
    }
  }, []);

  const handleCancel = async (id) => {
    try {
      const res = await APICancelOrder(id);
      if (res.message) {
        toast.success(res.message);
        setTimeout(() => {
          window.location.href = "/orders";
        }, 2000);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const fetchOrderDetail = async () => {
      try {
        const user = await readFromDB("UserStore", "user");
        if (user) {
          setUser(user);
          const data2 = await readFromDB("Order", "orders");
          setOrder(data2);
          const data = await APIOrderDetail(user.id, orderId);
          if (data) {
            setOrderDetail(data);
          } else {
            console.warn("No data returned from API.");
          }
        }
      } catch (error) {
        console.error("Error in fetchOrders:", error);
      }
    };
    fetchOrderDetail();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        if (orderDetail) {
          const storedProducts = await readFromDB("Products", "products");
          const filteredProducts = [];
          orderDetail.forEach((item) => {
            const product = storedProducts.find(
              (product) => product.id === item.pro_id
            );
            if (product) {
              filteredProducts.push({
                ...product,
                qty: item.qty,
                price: item.price,
              });
            }
          });
          setProducts(filteredProducts);
        }
      } catch (error) {
        toast.error("Error in fetching products:");
      }
    };
    fetchProducts();
  }, [orderDetail]);

  const currentOrder = order.find((item) => item.order_id == orderId);
  const orderStatus = currentOrder?.status;

  // Print Receipt Function
  const printReceipt = () => {
    const printContents = document.getElementById("recipt").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload to restore original view
  };

  return (
    <>
      <Navbar />
      <div className="container order-detail-container">
        <div className="d-flex align-items-center mb-3">
          <Link to="/Orders" className="text-decoration-none text-black">
            <TbArrowBack style={{ fontSize: "25px" }} /> Order Detail #{orderId}
          </Link>
        </div>

        <div id="recipt" className="receipt-card p-3">
          <img
            className="mb-3 d-flex align-items-center mx-auto"
            src="/assets/logo.png"
            height="50px"
            alt="logo"
          />
          <h5 className="text-center mb-3">Grocy Billing Receipt</h5>
          <div className="d-flex justify-content-between text-muted">
            <small className="text-start">
              <strong>Name:</strong> {User.username}
            </small>
            <small className="text-end">
              <strong>Status:</strong> {orderStatus}
            </small>
          </div>
          <div className="d-flex justify-content-between text-muted mt-2">
            <small className="text-start">
              <strong>Date:</strong> {currentOrder?.ex_date}
            </small>
            <small className="text-end">
              <strong>Time:</strong> {currentOrder?.ex_time}
            </small>
          </div>

          <div className="d-flex justify-content-between text-muted mt-2">
            <small className="text-start">
              <strong>Phone:</strong>
            </small>
            <small className="text-end">{currentOrder?.phone}</small>
          </div>

          {/* Address */}
          <div className="d-flex justify-content-between text-muted mt-2">
            <small className="text-start">
              <strong>Address:</strong>
            </small>
            <small className="text-end">{currentOrder?.address}</small>
          </div>

          {products.map((item, index) => (
            <div
              key={index}
              className="product-item d-flex justify-content-between py-2 border-bottom"
            >
              <div>
                <strong>
                  {index + 1}. {item.name}
                </strong>
                <div className="text-muted">
                  {item.unit_name} x {item.qty}
                </div>
              </div>
              <div className="d-flex align-items-center text-end">
                Rs. {item.price * item.qty}
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-between pt-3">
            <span className="fw-bold">Total:</span>
            <span>
              Rs.{" "}
              {orderDetail.reduce(
                (total, item) => total + item.price * item.qty,
                0
              )}
            </span>
          </div>
          <div className="d-flex justify-content-between mt-2 pt-3 border-top">
            <span className="fw-bold">Delivery:</span>
            <span>Rs. {currentOrder?.delivery_charges}</span>
          </div>
          <div className="d-flex justify-content-between mt-2 pt-3 border-top">
            <span className="fw-bold">SubTotal:</span>
            <span>
              Rs.{" "}
              {orderDetail.reduce(
                (total, item) => total + item.price * item.qty,
                0
              ) + parseInt(currentOrder?.delivery_charges || 0)}
            </span>
          </div>
        </div>

        <div className="text-center mt-3">
          {currentOrder?.status == "que" && (
            <div className="d-flex justify-content-center mb-2 position-relative">
              <button
                className="btn btn-danger"
                onClick={() => handleCancel(currentOrder.order_id)}
              >
                Cancel Order
              </button>
            </div>
          )}

          <button
            onClick={() => {
              const recipt1 = document.getElementById("recipt");
              const recipt2 = document.getElementById("recipt2");
              recipt1.style.display = "block";
              recipt2.style.display = "none";
              printReceipt();
            }}
            className="btn btn-primary"
          >
            Print
          </button>
        </div>

        {/* Order */}

        <div id="recipt2" className="order-overview mt-3">
          <h6 className="text-center">Order Overview</h6>
          <div className="d-flex justify-content-between text-muted mt-2">
            <small className="text-start">
              <strong>Order ID:</strong> {orderId}
            </small>
            <small className="text-end">
              <strong>Status:</strong> {orderStatus}
            </small>
          </div>

          {/* Date */}
          <div className="d-flex justify-content-between text-muted mt-2">
            <small className="text-start">
              <strong>Date:</strong> {currentOrder?.ex_date}
            </small>
            <small className="text-end">
              <strong>Time:</strong> {currentOrder?.ex_time}
            </small>
          </div>

          {/* Total */}
          <div className="d-flex justify-content-between text-muted mt-2">
            <small className="text-start">
              <strong>Total Items:</strong> {products.length}
            </small>
            <small className="text-end">
              <strong>Total Amount:</strong> Rs.{" "}
              {orderDetail.reduce(
                (total, item) => total + item.price * item.qty,
                0
              ) + parseInt(currentOrder?.delivery_charges || 0)}
            </small>
          </div>

          <div className="d-flex justify-content-between text-muted mt-2">
            <small className="text-start">
              <strong>created at:</strong> {currentOrder?.created_at}
            </small>
          </div>
          {currentOrder?.cancel_time && (
            <div className="d-flex justify-content-between text-muted mt-2">
              <small className="text-start">
                <strong>Cancel at:</strong> {currentOrder?.cancel_time}
              </small>
            </div>
          )}
          {currentOrder?.complete_time && (
            <div className="d-flex justify-content-between text-muted mt-2">
              <small className="text-start">
                <strong>Complete at:</strong> {currentOrder?.complete_time}
              </small>
            </div>
          )}
          {currentOrder?.on_way && (
            <div className="d-flex justify-content-between text-muted mt-2">
              <small className="text-start">
                <strong>On Way:</strong> {currentOrder?.on_way}
              </small>
            </div>
          )}
          {currentOrder?.process_time && (
            <div className="d-flex justify-content-between text-muted mt-2">
              <small className="text-start">
                <strong>Process time:</strong> {currentOrder?.process_time}
              </small>
            </div>
          )}
          {currentOrder?.ready_time && (
            <div className="d-flex justify-content-between text-muted mt-2">
              <small className="text-start">
                <strong>Ready time:</strong> {currentOrder?.ready_time}
              </small>
            </div>
          )}

          <div>
            <table className="table table-responsive table-striped mt-1 text-center mx-auto table-text">
              <thead>
                <tr className="align-middle text-center">
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {products.map((item, index) => (
                  <tr key={index} className="align-middle text-center ">
                    <td>
                      <img src={item.img[0]} width="30px" alt={item.name} />
                    </td>
                    <td>{item.name}</td>
                    <td>{item.qty}</td>
                    <td>{item.price}</td>
                    <td>Rs.{item.price * item.qty}</td>
                  </tr>
                ))}
                <tr className="align-middle  ">
                  <td className="text-end fw-bold" colSpan="4">
                    Total
                  </td>
                  <td>
                    Rs.{" "}
                    {orderDetail.reduce(
                      (total, item) => total + item.price * item.qty,
                      0
                    )}
                  </td>
                </tr>
                <tr className="align-middle  ">
                  <td className="text-end fw-bold" colSpan="4">
                    Delivery
                  </td>
                  <td>Rs. {currentOrder?.delivery_charges}</td>
                </tr>
                <tr className="align-middle  ">
                  <td className="text-end fw-bold" colSpan="4">
                    SubTotal
                  </td>
                  <td>
                    Rs.{" "}
                    {orderDetail.reduce(
                      (total, item) => total + item.price * item.qty,
                      0
                    ) + parseInt(currentOrder?.delivery_charges || 0)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div style={{ height: "60px" }}>&nbsp;</div>
      <Toolbar />
    </>
  );
}

export default OrderDetail;
