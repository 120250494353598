import { openDB } from 'idb';

const DATABASE_NAME = 'Grocy';
const Store_user = 'UserStore';
const Store_product = 'Products';
const Store_category = 'Category';
const Store_cart = 'Cart';
const Store_order = 'Order';
const Store_address = 'Address';
const Store_banner = 'Banner';
const RP_Orders = 'rp_orders';
const rao_History = 'RAO_History';
const SA_Orders = 'sAllOrders';
const SR_Orders = 'sReadyOrders';
const SH_Orders = 'sHistoryOrders';
const SDH_Orders = 'sDHistoryOrders';
const Setting = 'Setting';
const Store_arr = [Store_user, Store_product, Store_category, Store_cart,Store_order,Store_address,Store_banner,Setting,RP_Orders,rao_History,SA_Orders,SR_Orders,SH_Orders,SDH_Orders];
// Initialize the database with separate tables (object stores)
const initDB = async () => {
  return await openDB(DATABASE_NAME, 1, {
    upgrade(db) {
      Store_arr.forEach((store) => {
        if (!db.objectStoreNames.contains(store)) {
          db.createObjectStore(store);
        }
      });
    },
  });
};

// CREATE: Save data to IndexedDB
export const createInDB = async (storeName, key, data) => {
  const db = await initDB();
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.put(data, key); // Storing data with a key
  await tx.done;
};

//READ: Retrieve data from IndexedDB
export const readFromDB = async (storeName, key) => {
  const db = await initDB();
  const tx = db.transaction(storeName, 'readonly');
  const store = tx.objectStore(storeName);
  const data = await store.get(key); // Get data by key
  await tx.done;
  return data;
};

// UPDATE: Update data in IndexedDB
export const updateInDB = async (storeName, key, newData) => {
  const db = await initDB();
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.put(newData, key); // Update data for the specific key
  await tx.done;
};

// DELETE: Delete data from IndexedDB
export const deleteFromDB = async (storeName, key,value) => {
  const db = await initDB();
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(key);
  await store.delete(value); // Delete data by key
  await tx.done;
};

export const LogOut = async (storeName, key) => {
  const db = await initDB();
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.delete(key); // Delete data by key
  await tx.done;
};
