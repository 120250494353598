import { readFromDB, updateInDB, createInDB } from "../../indexedDB";

function Wash(){
    if (!caches) {
        return;
    }else {
        caches.keys().then(function(names) {
            for (var i = 0; i < names.length; i++) {
                caches.delete(names[i]);
            }
        });
    }
    localStorage.clear();
    sessionStorage.clear();
    var token = localStorage.getItem("token");
    readFromDB("Setting", "token").then(data => {
        if (data) {
            updateInDB("Setting", "token", token);
        }
        else {
            createInDB("Setting", "token", token);
        }
    });
    window.location.href = "/";
}
export default Wash