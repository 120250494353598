import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import "./MyAddress.css";
import { useRef, useState, useEffect } from "react";
import { readFromDB, createInDB } from "../../indexedDB";
import { APIAddress } from "../../api/Address";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { APICity } from "../../api/City";
import { APISubCity } from "../../api/SubCity";
function AddNewAddress() {
  const addref = useRef(null);
  const selectedType = useRef(null);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [address, setAddress] = useState([]);


  useEffect(() => {
    const fetchCities = async () => {
      try {
        const data = await new Promise((resolve, reject) => {
          APICity(resolve);
        });
        const select = document.getElementById("city");
        select.innerHTML = "";
        data.forEach((city) => {
          const option = document.createElement("option");
          option.value = city.id;
          option.textContent = city.name;
          select.appendChild(option);

        });
        
        getSubCities();
      } catch (error) {
        toast.error("Error fetching cities:");
      }
    };
    fetchCities();
  }, []);

  async function getSubCities() {
    const cityId = document.getElementById("city").value;
    try {
      const data = await APISubCity(cityId);
      const select = document.getElementById("subCity");
      select.innerHTML = "";
      data.forEach((city) => {
        const option = document.createElement("option");
        option.value = city.id;
        option.textContent = city.name;
        select.appendChild(option);

      });
    } catch (error) {
      toast.error("Error fetching cities:");
    }
    
  }

  
  useEffect(() => {
    (async () => {
      const fetchedUser = await readFromDB("UserStore", "user");
      const address=await readFromDB("Address", "address");
      setAddress(address);
      setUser(fetchedUser);
    })();
  }, []);

  const NewAddress = async () => {
    const type = selectedType.current?.value;
    if (!type || type.trim() == "") {
      toast.error("Please select an address type (Office or Home).");
      return;
    }

    const add = addref.current?.value;
    if (!add || add.trim() == "") {
      toast.error("Address field cannot be empty!");
      return;
    }

    const SubCityId = document.getElementById("subCity").value;

    try {
      const data = await APIAddress(user.id, add, type, SubCityId);
      if (data.message) {
        const updatedAddresses = [...address, { address: add,subcity_id :SubCityId, code: type, subcity: data.subcity, city: data.city, id: data.id,status:data.status }];
        await createInDB("Address", "address", updatedAddresses);
        
        toast.success(data.message);

          navigate("/MyAddress");
      } else {
        toast.error(data.error || "An error occurred while saving the address.");
      }
    } catch (err) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <h3 className="d-flex justify-content-center mb-3">Add new Address</h3>

        <div className="row d-flex align-items-center">
          <label className="col-4 fw-bold">Title :</label>
          <input className="LOC col-8 my-2" placeholder="Address type" ref={selectedType} />
        </div>

        <div className="row d-flex align-items-center">
          <label className="col-4 fw-bold">City :</label>
          <select className="LOC col-8 my-2" id="city" onChange={getSubCities} >
            <option value="">Select City</option>
          </select>
        </div>

        <div className="row d-flex align-items-center">
          <label className="col-4 fw-bold">Sub City :</label>
          <select className="LOC col-8 my-2" id="subCity">
            <option value="">Select Sub City</option>
          </select>
        </div>


        <div className="row d-flex align-items-center">
          <label className="col-4 fw-bold">Address :</label>
          <input className="LOC col-8 my-2" placeholder="Enter Address" ref={addref} />
        </div>



        <div className="row d-flex w-100">
          <Link className="OrderButton col-6">
            <button
              className="d-flex justify-content-center align-items-center w-100"
              type="button"
              onClick={() => {
                NewAddress();
              }}
            >
              Save
            </button>
          </Link>

          <Link to={"/MyAddress"} className="OrderButton col-6">
            <button className="d-flex justify-content-center align-items-center w-100" type="button">
              Cancel
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default AddNewAddress;
