import "bootstrap/dist/css/bootstrap.min.css";
import "../ProPage/ProPage.css";
import Navbar from "../Navbar/Navbar";
import { useLocation, Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import { useEffect, useState } from "react";
import { readFromDB,createInDB } from "../../indexedDB";
import toast, { Toaster } from "react-hot-toast";
import {useNavigate } from "react-router-dom";
function Pro_Page() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedCatId = parseInt(queryParams.get("cat_id"));
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleAddToCart = async (pro_id) => {
    try {
      const user = await readFromDB("UserStore", "user"); // Fetch user details from IndexedDB
      const existingCart = await readFromDB("Cart", "cart");
      const existingQty = await readFromDB("Cart", "qty");
      if (user) {
        setIsLoggedIn(true);

        let cartToUpdate = existingCart == null ? [] : existingCart;
        let qtyToUpdate =
          existingQty == null
            ? []
            : Array.isArray(existingQty)
            ? existingQty
            : Object.values(existingQty);

        let isProductInCart = false;
        cartToUpdate.forEach((element, index) => {
          if (element == pro_id) {
            toast.error("Product already exists in the cart!");
            isProductInCart = true;
          }
        });
        if (!isProductInCart) {
          //ok state
          let productToAdd = false;
          products.forEach((element, index) => {
            if (element.id == pro_id) {
              productToAdd = true;
            }
          });
          if (!productToAdd) {
            toast.error(`Product not found!`);
          } else {
            cartToUpdate = [...cartToUpdate, pro_id]; // Add product to cart
            qtyToUpdate = [...qtyToUpdate, 1];
            await createInDB("Cart", "cart", cartToUpdate);
            await createInDB("Cart", "qty", qtyToUpdate);
            toast.success("Product added to cart successfully!");
          }
        }
      } else {
        toast.error("Please login first!");
        navigate("/Login");
      }
    } catch (error) {
      toast.error("Failed to add product to cart.");
    }
  };

  
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // Step 1: Fetch products from IndexedDB
        const storedProducts = await readFromDB("Products", "products");
                if (Array.isArray(storedProducts)) {
          setProducts(storedProducts);
        } else {
          console.warn("Products data is not an array:", storedProducts);
        }
      } catch (error) {
        console.error("Error fetching products from IndexedDB:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Filter products based on the selected category ID
  const filteredProducts = selectedCatId
    ? products.filter((item) => item.cat_id == selectedCatId)
    : products;

  // Render loading or no products message
  if (loading) {
    return (
      <div className="text-center">
        <h3>Loading products...</h3>
      </div>
    );
  }

  return (
    <>
      {/* <img className="bgTop" src="/assets/bgTop.png" alt="logo" /> */}

      <Navbar />

      <div className="container px-0">
        <div className="row d-flex align-items-center justify-content-between Details">
          <div className="col-auto">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link id="link" to="/Home">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/Category">
                  Category
                </Link>
              </li>
              <li className="breadcrumb-item active">Products</li>
            </ol>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((item) => (
                <div className="col-6 mt-3 ProCard" key={item.id}>
                  {/* <img src="./assets/CBG.png" className="lineart" alt="" />
                  <img src="./assets/CBG.png" className="lineart2" alt="" /> */}
                  <Link
                    to={`/Details?product_id=${item.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="PCard p-1 justify-content-center">
                      <img
                      src={
                        item.img[0]
                      }
                      alt={`${item.name}`}
                      loading="lazy"
                      />
                    <div className="PName">{item.name}</div>
                    <div className="PPrice d-flex px-2 py-2">
                      <strong >{item.unit_name}</strong>
                      <span className="text-success">{item.price}.Rs</span>
                      </div>
                    </div>
                  </Link>
                  <div className="ProductCardButton">
                    {/* <img src="./assets/btnbg.png" className="btnArt" alt="" />
                    <img src="./assets/btnbg.png" className="btnArt2" alt="" /> */}
                    <button className="CB" onClick={() => handleAddToCart(parseInt(item.id))}>Add To Bag</button>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center">
                <h3 className="mt-3">There are no products in this category</h3>
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ height: "60px" }}></div>
      <Toolbar />
    </>
  );
}

export default Pro_Page;
