import "../Cart/Cart.css";
import Navbar from "../Navbar/Navbar";
import Toolbar from "../ToolBar/Toolbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState,useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import { readFromDB,createInDB,updateInDB } from "../../indexedDB";
import { APIPlaceOrder } from "../../api/PlaceOrder";

function Cart() {


    const today = new Date().toISOString().split('T')[0];
    const [Slot, setSlot] = useState([]);
    const [selectedDate, setSelectedDate] = useState(today);
    const currentTime = new Date();
 
    const slots = [
      { label: "8 AM-10 AM", start: 8, end: 9.5 },
      { label: "10 AM-12 PM", start: 10, end: 11.5 },
      { label: "12 PM-2 PM", start: 12, end: 13.5 },
      { label: "2 PM-5 PM", start: 14, end: 16.5 },
      { label: "5 PM-7 PM", start: 17, end: 18.5 },
      { label: "7 PM-9 PM", start: 19, end: 20.5 },
    ];
 
    const handleDateChange = (e) => {
      setSelectedDate(e.target.value);
    };
 
    const isSlotDisabled = (slot) => {
      // If date is not today, all slots are active
      if (selectedDate !== currentTime.toISOString().substr(0, 10)) {
        return false;
      }
      // Check current hour
      const currentHour = currentTime.getHours();
      return currentHour >= slot.end;
    };
 



  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [quantities, setQuantities] = useState([]);
  let [subtotal, setSubtotal] = useState(0);
  const [filteredProducts, setProducts] = useState([]);
  const [Dexist, setDexist] = useState(false);
  const delivery = 100;
  const [user, setUser] = useState([]);
  const [Address,SetAddress] = useState([]);
  let [orderAddress, setOrderAddress] = useState([]);
  let [orderAddressID, setOrderAddressID] = useState([]);
  const [storedProducts, setStoredProducts] = useState([]);
  const [storedQuantities, setStoredQuantities] = useState([]);
 
 


  useEffect(() => {
    const fetchData = async () => {
      const fetchedUser = await readFromDB("UserStore", "user");
      if (!fetchedUser) {
        navigate("/Login");
      }
      const fetchedAddress = await readFromDB("Address", "address");
      setUser(fetchedUser);
      SetAddress(fetchedAddress);
    };
    fetchData();
  }, []);

  const calculateSubtotal = (storedProducts) => {
    if (storedProducts.length == 0) return;
    let newSubtotal = 0;
    filteredProducts.forEach((item) => {
      storedProducts.forEach((elem,index) => {
        if (elem == item.id) {
          newSubtotal += item.price * storedQuantities[index];
        }
      });
    });
    setSubtotal(newSubtotal);
  };
  
  const incrementQuantity = (id) => {
    storedProducts.forEach((item,index) => {
      if (item == id) {
        storedQuantities[index] = storedQuantities[index] + 1;
        const updatedQuantities = storedQuantities;
        updateInDB("Cart", "qty", updatedQuantities);
        calculateSubtotal(storedProducts);
        return updatedQuantities;
      }
    })
  }
  //Decrement
  const decrementQuantity = (id) => {
    storedProducts.forEach((item,index) => {
      if (item == id) {
        if(storedQuantities[index] == 1) return;
        storedQuantities[index] = storedQuantities[index] - 1;
        const updatedQuantities = storedQuantities;
        updateInDB("Cart", "qty", updatedQuantities);
        calculateSubtotal(storedProducts);
        return updatedQuantities;
      }
    })
  };

  const handleQuantityChange = (id, newQuantity) => {
    storedProducts.forEach((item,index) => {
      if (item == id) {
        if(newQuantity < 1) return;
        storedQuantities[index] = newQuantity;
        const updatedQuantities = storedQuantities;
        updateInDB("Cart", "qty", updatedQuantities);
        calculateSubtotal(storedProducts);
        return updatedQuantities;
      }
    })
  }
//Get products
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // Fetch products from IndexedDB
        const storedProducts = await readFromDB("Cart", "cart");
        const storedQuantities = await readFromDB("Cart", "qty");
        setStoredProducts(storedProducts);
        setStoredQuantities(storedQuantities);

        if (Array.isArray(storedProducts)) {
          const cartproducts = await readFromDB("Products", "products");
          const filteredProducts = cartproducts.filter(product => storedProducts.includes(Number(product.id)));
          setQuantities(storedQuantities);
          setDexist(true);
          setProducts(filteredProducts);

          // Delete products from cart and qty if they are not in filteredProducts
          const updatedProducts = [];
          const updatedqty = [];
          storedProducts.forEach((elem,index) => {
            if(filteredProducts.some(product => product.id == elem)) {
              updatedProducts.push(elem);
              updatedqty.push(storedQuantities[index]);
            }
          });
          updateInDB("Cart", "cart", updatedProducts);
          updateInDB("Cart", "qty", updatedqty);

          if (storedProducts.length == 0) return;
          let newSubtotal = 0;
          filteredProducts.forEach((item) => {
            updatedProducts.forEach((elem,index) => {
              if (elem == item.id) {
                newSubtotal += item.price * updatedqty[index];
              }
            });
          });
          setSubtotal(newSubtotal);
        }    
      } catch (error) {
        toast.error("Error fetching products");
      }
    };
    fetchProducts();
  }, []);


//Delete  
  const handleDeleteProduct = async (id) => {
    try {
      // Remove product from IndexedDB
      const updatedProducts = [];
      const updatedqty = [];
      storedProducts.forEach((elem,index) => {
        if(elem != id){
           updatedProducts.push(elem);
           updatedqty.push(storedQuantities[index]);
        }
      });
      setStoredProducts(updatedProducts);
      setStoredQuantities(updatedqty);
      // for back await deleteFromDB("Cart",id);
      await updateInDB("Cart", "cart", updatedProducts);  
      await updateInDB("Cart", "qty", updatedqty);  

      // for front Update filteredProducts
      const updatedFilteredProducts = [];
      filteredProducts.forEach((item) => item.id !== id && updatedFilteredProducts.push(item));
      setProducts(updatedFilteredProducts);
      if (updatedqty.length == 0) {
        setSubtotal(0);
      }
        // setQuantities(updatedQuantities);
     
      calculateSubtotal(updatedProducts);
      toast.success("Product removed from cart.");
    } catch (error) {
      toast.error("Failed to remove product from cart.");
    }
  };



  //Place order
const fetchdata=async()=>{
  let cartpro = await readFromDB("Cart", "cart");
  let cartqty = await readFromDB("Cart", "qty");
  let user = await readFromDB("UserStore", "user");
  let uid = user.id;
  let qty = Object.values(cartqty).join(",");
  let pro = cartpro.join(",");


    let pho = document.getElementById("phone").value;
    if (pho == "") {
      toast.error("Please enter a phone number.");
      return;
    }
  let total = subtotal + delivery;
  let date=document.getElementById("date").value;
  let timeSlot = Slot.label ? Slot.label : "in 1 hour";

  if(orderAddress == "" || orderAddress == null){
    let allAddress = await readFromDB("Address", "address");
    if(allAddress.length > 0){
      orderAddress = `${allAddress[0].address}, ${allAddress[0].city}, ${allAddress[0].subcity}`;
      orderAddressID = allAddress[0].subcity_id;
    }else{
      toast.error("Please add an address.");
      return;
    }
  }
//Send Data
  let res = await APIPlaceOrder(uid, pro, qty, orderAddress,orderAddressID, pho, date, timeSlot, total);
  //Empty cart
  if(res.message){
    await createInDB("Cart", "cart", []);
    await createInDB("Cart", "qty", []);
    setProducts([]);
    setQuantities([]);
    calculateSubtotal([]);
    document.getElementById("closeModal").click();
    navigate("/ConfirmOrder");
    toast.success("Order Placed Successfully!");
  }else{
  toast.error(res.error);
}
}
function checkkk() {
  const phoneElement = document.getElementById("phone");
  if(quantities.length == 0 || quantities == []){
    toast.error("No products found in cart.");
    let elem = document.getElementById("placeOrder");
  if (elem) {
    elem.removeAttribute("data-toggle");
  }
   return;
  }
  const address=readFromDB("Address", "address");
  if(address == null || address == "") {
    toast.error("Please add a delivery address.");
    return;
  }
  if (!phoneElement || phoneElement.value == "") {
    toast.error("Phone number is empty.");
    return;
  }

  let elem = document.getElementById("placeOrder");
  elem.removeAttribute("onclick");
  elem.setAttribute("data-toggle", "modal");
  elem.click();
}


  return (
    <>
      <Navbar />
      <div className="container-fluid px-0">
        {/* Breadcrumb Section */}
        <div className="row mt-3 mb-3">
          <div className="col-12">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link id="link" to="/Home">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/Cart">
                  Cart
                </Link>
              </li>
            </ol>
          </div>
        </div>

        <div className="container">
          <div className="row d-flex justify-content-between">
            {/* Cart Items */}
            {filteredProducts.length > 0 ? (
               
              (
                filteredProducts.map((item,index) => (
                <div key={`${item.id}-${index}`} className="row CartP align-items-center ">
                  {/* Image Section */}
                  <div className="col-4">
                    <img
                      className="CartPI"
                      src={`${item.img[0]}`}
                      alt={item.name}
                      loading="lazy"

                    />
                  </div>
                  {/* Product Details Section */}
                  <div className="col-8 d-flex flex-column justify-content-between">
                    <div className="row align-items-center">
                      <div className="col-8">
                        <div className="CartPN">{item.name}</div>
                      </div>
                      <div className="col-4 d-flex justify-content-between">
                        {/* Quantity Adjustment Buttons */}
                        <Link to="/Cart">
                          <button
                            className="bin-button"
                            onClick={() => {
                              handleDeleteProduct(item.id);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div className="row align-items-center CartPP">
                      <div className="col-5">
                        <small>{item.unit_name}</small>
                        <br />
                        <strong style={{ fontSize: "16px" }}>
                          Rs. {item.price * (storedProducts.findIndex((prod) => prod == item.id) !== -1 ? storedQuantities[storedProducts.findIndex((prod) => prod == item.id)] : 1)}
                        </strong>
                      </div>
                      <div className="col-7 d-flex align-items-center gap-2 CCardBtn ms-auto">
                        {/* Quantity Adjustment Buttons */}
                        <button
                          className="btn btnM"
                          onClick={() => decrementQuantity(item.id)}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <input 
                          type="number" 
                          className="form-control form-control-sm shadow-none text-center" 
                          style={{ width: '30px' }} 
                          value={storedQuantities[storedProducts.findIndex((prod) => prod == item.id)] || 1}
                          onChange={(e) => { handleQuantityChange(item.id, e.target.value); }}
                        />
                        <button
                          className="btn btnP"
                          onClick={() => incrementQuantity(item.id)}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <hr />
                </div>
              )))
            ) : (
              <h1 className="text-center">Cart is Empty</h1>
            )}

          </div>
          {/* Add More Products Button */}
          <Link className="AddButton my-4" to="/Pro_Page">
            <button>Add Products</button>
          </Link>

          {/* Delivery Date and Time */}
          <div className="row align-items-center Calandar mb-4">
            <div className="text-start">Expected Date and Time : </div>
            <div className="input-group mt-4">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faCalendar} id="CalanderIcon"/>
              </div>
              <input
              id="date"
                type="date"
                className="form-control shadow-none"
                               
                defaultValue={new Date().toISOString().substr(0, 10)}
                min={new Date().toISOString().substr(0, 10)}
                onChange={handleDateChange}
              />
            </div>
          </div>

          <div className="Time mb-3" id="time-slot">
        {slots.map((slot) => (
          <button
            className="slots"
            key={slot.label}
            onClick={(e) => setSlot(slot)}
            disabled={isSlotDisabled(slot)}  
                      
          >
            {slot.label}
          </button>
        ))}
      </div>
      <div>
        <p className="text-start mt-3">
          Your order will arrive on {selectedDate}
          {(Slot && !isSlotDisabled(Slot) && Slot.label) ? (
            <span> between {Slot.label}</span>
          ) : (
            <span> in 1 hour</span>
          )}
        </p>
      </div>

          {/* Delivery Location */}
          <div className="row align-items-center lochead">
            <div id="deliveryLocation" className="col-8 text-start">
              <span style={{fontWeight: "bold"}}>Delivery Location: </span><br/>
              {typeof Address !== 'undefined' && Address.length ? `${Address[0].address}, ${Address[0].subcity}, ${Address[0].city}` : "No address added"}
            </div>
            <div className="col-4 text-end">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                select
              </button>

              <div
                className="modal fade text-start text-dark"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Delivery Location
                      </h5>
                      <button
                        type="button"
                        id="closeModal"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      {typeof Address != 'undefined' && Address.length > 0 ? (
                        Address.map((addr, index) => (
                          <div key={index} style={{color: addr.status == 'inactive' ? 'grey' : ''}}
                          onClick={() => {
                            if(addr.status != 'inactive')
                              {
                              setOrderAddress( `${addr.address}, ${addr.subcity}, ${addr.city}` );
                              setOrderAddressID(addr.subcity_id);
                              document.getElementById("deliveryLocation").innerHTML = `<strong>Delivery location:</strong><br/><small> ${addr.address}, ${addr.subcity}, ${addr.city} </small>`;
                              document.getElementById("closeModal").click();
                            }
                          }}>
                            <h5 className="CartAddCode">{addr.code}</h5>
                            <p className="CartAddCity ">{addr.address}, {addr.subcity}, {addr.city} {addr.status == 'inactive' ? ' (inactive)' : ''}</p>
                            <hr/>
                          </div>
                        ))
                      ) : (
                        <div className="text-center">
                          <h4>No address found</h4>
                          <button className="btn btn-success"
                          onClick={() => {
                            document.getElementById("closeModal").click();
                            navigate("/MyAddress");
                          }}>
                            Add new address
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>




          <div className="row mx-auto align-items-center lochead mt-2 ">
            <div className="col-6 text-start">Phone Number:</div>
            <div className="col-6 w-50">
              <input
              id="phone"
                type="text"
                className="form-control form-control-sm shadow-none text-end"
                placeholder="Enter your phone number"
                required
                value={user.phone || ''}
                onChange={(e) => setUser({...user, phone: e.target.value})}
              />
            </div>

          {/* Order Summary */}
          <div className="row align-items-center subtotal mt-2 ">
            <div className="col-6 text-start">Sub Total</div>
            <div className="col-6 text-end mx-0 px-0">Rs {subtotal}</div>
          </div>
          <div className="row align-items-center delivery mt-2">
            <div className="col-6 text-start">Delivery Charges</div>
            <div className="col-6 text-end mx-0 px-0">Rs {delivery}</div>
          </div>
          <div className="row align-items-center total mt-2">
            <div className="col-6 text-start">Total</div>
            <div className="col-6 text-end mx-0 px-0">Rs {subtotal + delivery}</div>
          </div>
          <hr />

          {/* Place Order Button */}
          <div className="OrderButton">
            <button
            type="button"
            id="placeOrder"
            className="btn btn-primary"
            onClick={() => checkkk()}
            data-target="#exampleModalCenter"
          >
            Confirm order
          </button>


            {/* Modal */}
            <div
              className="modal fade"
              id="exampleModalCenter"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              // aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      id="closeModala"
                      className="btn-close"
                      data-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body text-center">
                    <h4>
                      Do you want to
                      <span className="text-success"> confirm your order?</span>
                    </h4>
                    <div className="d-flex justify-content-center gap-2">
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-dismiss="modal"
                        id="closeModal"
                      >
                        Decline
                      </button>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={async () => {
                          fetchdata();
                          document.getElementById("closeModala").click();
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: "60px" }}>&nbsp;</div>
        <Toolbar />
      </div>
    </>
  );
}

export default Cart;