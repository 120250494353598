import RNavbar from "../Navbar/RNavbar";
import { useNavigate } from "react-router-dom";
import { TbArrowBack } from "react-icons/tb";
import { Link } from "react-router-dom";
import { MdDeliveryDining } from "react-icons/md";
import { useEffect, useState } from "react";
import { readFromDB, createInDB } from "../../../indexedDB";
import { toast } from "react-hot-toast";

import "./RCOrders.css";
import { APITodayHistory } from "../../../api/Todayhistory.js";

function RPendingOrders() {
  const [User, setUser] = useState([]);
  const [order, setOrder] = useState([]);

  
  useEffect(() => {
    const fetchUser = async () => {
      const user = await readFromDB("UserStore", "user");
      if (user) {
        setUser(user);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    if (User.id && User.status == "rider") {
      const fetchData = async () => {
        const data = await APITodayHistory(User.id);
        const data2= await readFromDB("RAO_History", "RAO");
        console.log(data2);
        setOrder(data.orders);
        console.log(order);
      };
      fetchData();
    }
  }, [User]);

  const navigate = useNavigate();

  return (
    <>
      <RNavbar />
      <div className="order-header d-flex align-items-center mb-3">
        <Link to="/RDashboard" className="text-decoration-none text-black">
          <TbArrowBack style={{ fontSize: "25px" }} /> Daily Completed Orders
        </Link>
      </div>

      <div className="container">
        {order && order.length > 0 ? (
          order.map((item) => (

            <>
            <div className="order-card row align-items-center" key={item.order_info.order_id} onClick={() => navigate(`/RCDetail?order_id=${item.order_info.order_id}`)} >
              {/* Icon */}
              <div className="order-icon col-2 d-flex align-items-center justify-content-center">
                <MdDeliveryDining style={{ width: "60px", height: "60px" }} />
              </div>

              {/* Order Details */}
              <div className="order-details col-6 d-flex flex-column">
                <div className="order-number">Order No: {item.order_info.order_id}</div>
                <div className="order-area">Area: {item.order_info.address}</div>
              </div>

              {/* Order Price */}
              <div className="order-price col-4 d-flex flex-column align-items-end">
                <div className="order-amount">Rs. {item.order_info.total}</div>
                <div className="order-date">items: {item.products.length}</div>
              </div>

            </div>
          <hr />
          </>
          ))
        ) : (
          <div className="no-orders-container text-center mt-5">
  <div className="no-orders-card mx-auto">
    <MdDeliveryDining className="no-orders-icon" />
    <h3 className="no-orders-heading">No Orders Yet</h3>
    <p className="no-orders-text">
      It looks like you haven't placed any orders. Start exploring our products and make your first purchase today!
    </p>
  </div>
</div>
        )}
      </div>
    </>
  );
}

export default RPendingOrders;
