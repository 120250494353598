import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { readFromDB } from "../../../indexedDB";
import { TbArrowBack } from "react-icons/tb";
import SNavbar from "../SNavbar/SNavbar";
import { toast } from "react-hot-toast";
import { APISProcessOrder } from "../../../api/Seller/SProcessOrder";
import { APISReadyOrder } from "../../../api/Seller/SReadyOrder";

function SOrderDetails() {
  const [orderDetail, setOrderDetail] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("uid");
  console.log(orderId);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await readFromDB("sAllOrders", "SAO");

        const orderData = data.find(
          (order) => order?.seller_order?.uid == orderId
        );

        setOrderDetail(orderData);
      } catch (error) {
        toast.error("Error in fetching data");
      }
    };

    fetchData();
  }, [orderId, navigate]);

  const handleProcessOrder = async () => {
    setIsProcessing(true);
    const processOrderCallback = (res) => {
      if (res.message) {
        toast.success(res.message);
        setIsProcessing(false);
        return;
      } else {
        toast.error(res.error);
        setIsProcessing(false);
        return;
      }
    };
    try {
      await APISProcessOrder(
        orderDetail?.seller_order?.seller_id,
        orderDetail?.seller_order?.uid,
        processOrderCallback
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleReadyOrder = async () => {
    setIsReady(true);
    const ReadyOrderCallback = (res) => {
      if (res?.message) {
        toast.success(res.message);
        setIsReady(false);
        return;
      } else {
        toast.error(res.error);
        setIsReady(false);
        return;
      }
    };
    try {
      await APISReadyOrder(
        orderDetail?.seller_order?.seller_id,
        orderDetail?.seller_order?.uid,
        ReadyOrderCallback
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <SNavbar />
      <div
        className="RPODetails mt-4 p-3 border rounded"
        style={{ maxWidth: "600px", backgroundColor: "#f9f9f9" }}
      >
        {/* Back Button */}
        <div className="order-header d-flex align-items-center mb-4">
          <Link to="/SAllOrders" className="text-decoration-none text-black">
            <TbArrowBack style={{ fontSize: "20px" }} /> Order
          </Link>
        </div>

        {/* Receipt Header */}
        <h5 className="text-center mb-3">Order Detail</h5>
        <hr />

        {/* Order Information */}
        <div className="mb-3">
          <p>
            <strong>Order status:</strong> {orderDetail?.seller_order?.status}
          </p>
          <p>
            <strong>Order ID :</strong> {orderDetail?.seller_order?.uid}
          </p>
          <div className="row mb-1">
            <p className="fw-bold">Rider Details:</p>
            <div className="col-6">
              <p>
                <strong>Rider:</strong>{" "}
                {orderDetail?.rider_details?.username || "N/A"}
              </p>
            </div>
            <div className="col-6">
              <p className="mb-1">
                <strong>Phone:</strong>{" "}
                {orderDetail?.rider_details?.phone || "N/A"}
              </p>
            </div>
          </div>
        </div>
        {/* Products Table */}
        <div className="">
          <p className="fw-bold">Product Details:</p>
          <p>
            <strong>Product:</strong> {orderDetail?.product?.name}
          </p>
          <p>
            <strong>Quantity:</strong> {orderDetail?.product?.qty} x
            {orderDetail?.product?.unit_id}
          </p>
          <p>
            <strong>description:</strong> {orderDetail?.product?.description}
          </p>
        </div>

        {/* Order Status Section */}
        <div className="mt-3">
          {orderDetail?.seller_order?.created_at && (
            <p className="mb-1">
              <strong>Created Time:</strong>{" "}
              {orderDetail?.seller_order?.created_at}
            </p>
          )}
          {orderDetail?.seller_order?.deleted_at && (
            <p className="mb-1">
              <strong>Deleted Time:</strong>{" "}
              {orderDetail?.seller_order?.deleted_at}
            </p>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center mt-2">
        {!isProcessing && orderDetail?.seller_order?.status != "preparing" && (
          <button className="btn me-2 btnprocess" onClick={handleProcessOrder}>
            {isProcessing ? "Loading..." : "Processing"}
          </button>
        )}
        <button
          className="btn btnready"
          onClick={handleReadyOrder}
          disabled={isReady}
        >
          {isReady ? "Loading..." : "Ready"}
        </button>
      </div>
    </>
  );
}

export default SOrderDetails;
