import React, { useState, useEffect } from "react";
import { readFromDB } from "../../indexedDB";
import { FaSearch } from "react-icons/fa";
import Navbar from "../Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import "./Search.css";
import toast from "react-hot-toast";
import { createInDB } from "../../indexedDB";
import Toolbar from "../ToolBar/Toolbar";
function Search() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const navigate = useNavigate();
  const [IsLoggedIn, setIsLoggedIn] = useState(false);
  const [products, setProducts] = useState([]);

  // Fetch products from IndexedDB on page load
  useEffect(() => {
    const fetchProducts = async () => {
      const products = await readFromDB("Products", "products");
      setProducts(products);

      // Get the search term from the URL and filter products
      const params = new URLSearchParams(window.location.search);
      const searchTermFromURL = params.get("searchTerm");
      if (searchTermFromURL) {
        setSearchTerm(searchTermFromURL);
        filterProducts(searchTermFromURL);
      }
    };
    fetchProducts();
  }, []);

  const handleAddToCart = async (pro_id) => {
    try {
      const user = await readFromDB("UserStore", "user"); // Fetch user details from IndexedDB
      const existingCart = await readFromDB("Cart", "cart");
      const existingQty = await readFromDB("Cart", "qty");

      if (user) {
        setIsLoggedIn(true);

        let cartToUpdate = existingCart == null ? [] : existingCart;
        let qtyToUpdate =
          existingQty == null
            ? []
            : Array.isArray(existingQty)
            ? existingQty
            : Object.values(existingQty);

        let isProductInCart = false;
        cartToUpdate.forEach((element, index) => {
          if (element == pro_id) {
            toast.error("Product already exists in the cart!");
            isProductInCart = true;
          }
        });
        if (!isProductInCart) {
          //ok state
          let productToAdd = false;
          products.forEach((element, index) => {
            if (element.id == pro_id) {
              productToAdd = true;
            }
          });
          if (!productToAdd) {
            toast.error(`Product not found!`);
          } else {
            cartToUpdate.push(pro_id); // Add product to cart
            qtyToUpdate.push(1);
            await createInDB("Cart", "cart", cartToUpdate);
            await createInDB("Cart", "qty", qtyToUpdate);
            toast.success("Product added to cart successfully!");
          }
        }
      } else {
        toast.error("Please login first!");
        navigate("/Login");
      }
    } catch (error) {
      toast.error("Failed to add product to cart.");
    }
  };


  // Filter products dynamically as the search term changes
  useEffect(() => {
    if (searchTerm.trim() !== "") {
      filterProducts(searchTerm);
    }
  }, [searchTerm]);

  // Filter products based on the search term
  const filterProducts = (value) => {
    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  // Handle input change and update the URL
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    navigate(`/Search?searchTerm=${value}`);
  };

  return (
    <>
      <Navbar />
      <div className="search-container">
        <div className="input-group">
          <input
            type="text"
            placeholder="Search for products..."
            value={searchTerm}
            onChange={handleInputChange}
            className="form-control search-input shadow-none"
          />
          <FaSearch className="search-icon" />
        </div>

        {
          filteredProducts.length === 0 ? (
            <div className="row">
              <div className="col-12 mt-3">
                <div className="d-flex justify-content-center p-1 ">
                  <div className="">No Product Found</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              {filteredProducts.map((item) => (
                <div key={item.id} className="col-6 mt-3 ProCard">
                  <Link
                    to={`/Details?product_id=${item.id}`}
                    style={{ textDecoration: "none" }}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <div className="PCard p-1 justify-content-center">
                      <img
                        src={item.img[0]}
                        alt={`${item.name}`}
                        loading="lazy"
                      />
                      <div className="PName">{item.name}</div>
                      <div className="PPrice d-flex px-2 py-3">
                        <strong>{item.unit_name}</strong>
                        <span className="text-success">{item.price}.Rs</span>
                      </div>
                    </div>
                  </Link>
                  <div className="ProductCardButton">
                    <button
                      className="CB"
                      onClick={() => {
                        handleAddToCart(parseInt(item.id))
                        
                    }}
                    >
                      Add To Bag
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )
        }
      </div>
      <div style={{ height: "40px" }}>&nbsp;</div>
      <Toolbar/>
    </>
  );
}

export default Search;
