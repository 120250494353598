import Navbar from "../Navbar/Navbar";
import Toolbar from "../ToolBar/Toolbar";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { readFromDB } from "../../indexedDB";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaStar,
  FaLeaf,
} from "react-icons/fa";
import "./About.css";

function About() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const fetchedUser = await readFromDB("UserStore", "user");
      if (!fetchedUser) {
        navigate("/Login");
      }
      setUser(fetchedUser);
    })();
  }, [navigate]);

  useEffect(() => {
    setIsLoggedIn(!!user);
  }, [user]);

  return (
    <>
      <Navbar />
      {!isLoggedIn ? (
        <div className="text-center mt-4">
          <p>Please log in first.</p>
          <Link to="/Login" className="btn btn-primary">
            Go to Login
          </Link>
        </div>
      ) : (
        <div className="container-fluid px-0">
          <div className="row mt-2">
            <div className="col-10">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link id="link" to="/Home">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link id="link" to="/More">
                    More
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link id="link" to="/About">
                    About
                  </Link>
                </li>
              </ol>
            </div>
          </div>
          <div className="container  about-container">
            <h1 className="text-center mb-2 border-bottom">About Grocy</h1>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <p className="about-text text-justify">
                  Welcome to <strong>Grocy</strong>, your one-stop solution for
                  all grocery, food, vegetable, and kitchen needs. At Grocy, we
                  aim to make your shopping experience seamless and convenient
                  by bringing everything you need right to your doorstep.
                </p>
                <p className="about-text text-justify ms-justify d-flex justify-content-between">
                  Grocy is a proudly Pakistani platform designed to cater to the
                  unique needs of our people. From fresh produce sourced from
                  local farms to homemade delicacies crafted by talented chefs,
                  Grocy reflects the flavors and traditions of Pakistan.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <h3 className="d-flex mb-3 justify-content-center">
                  Our Features
                </h3>
                <ul className="list-group">
                  <li className="list-group-item">
                    🍝 <strong>Shop with Ease:</strong> Browse a wide range of
                    grocery items, fresh vegetables, and kitchen essentials.
                  </li>
                  <li className="list-group-item">
                    🛒 <strong>Become a Seller:</strong> Start your online store
                    and reach more customers with our platform.
                    <button
                      style={{
                        marginLeft: "6px",
                        borderRadius: "5px",
                        border: "none",
                        fontSize: "12px",
                        backgroundColor: "var(--primary-color)",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      Become a Seller
                    </button>
                  </li>
                  <li className="list-group-item">
                    🚲 <strong>Join as a Rider:</strong> Deliver happiness and
                    earn on your own schedule.
                    <button
                      style={{
                        borderRadius: "5px",
                        border: "none",
                        fontSize: "12px",
                        backgroundColor: "var(--primary-color)",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      Become a Rider
                    </button>
                  </li>
                  <li className="list-group-item">
                    👨‍🍳 <strong>Homemade Meals:</strong> Support local chefs and
                    enjoy delicious homemade food.
                  </li>
                  <li className="list-group-item">
                    🌿 <strong>Farm-to-Table:</strong> Fresh produce sourced
                    directly from Pakistani farms to your table.
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <h3 className="d-flex mb-3 mt-4 border-bottom justify-content-center">
                  Why Choose Grocy?
                </h3>
                <p className="about-text text-justify">
                  At Grocy, we prioritize quality, affordability, and community.
                  We connect local farmers, sellers, and chefs with customers
                  who value fresh and authentic products. Our user-friendly
                  platform ensures a hassle-free shopping experience, whether
                  you're ordering for your family or looking to support local
                  businesses.
                </p>
                <p className="about-text text-justify">
                  As a Pakistani app, we are committed to empowering our
                  community. Grocy creates opportunities for sellers and riders,
                  enabling them to earn and grow within their neighborhoods.
                  Together, we can build a stronger and more connected Pakistan.
                </p>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <h3 className="text-center mb-4 border-bottom">Credits</h3>
                <p className="text-center about-text  text-justify">
                  This platform was designed and developed by the talented team
                  at <strong>Grocy Developers</strong>. Special thanks to all
                  our users and local partners who make this platform possible.
                </p>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <h3 className="text-center mb-4 border-bottom">Contact Us</h3>
                <div className="d-flex justify-content-around">
                  <div className="text-center">
                    <FaPhoneAlt size={14} className="mb-2" />
                    <h6>Helpline</h6>
                    <p>051-000000</p>
                  </div>
                  <div className="text-center">
                    <FaEnvelope size={14} className="mb-2" />
                    <h6>Email</h6>
                    <p>support@grocy.pk</p>
                  </div>
                  <div className="text-center">
                    <FaMapMarkerAlt size={14} className="mb-2" />
                    <h6>Address</h6>
                    <p>Gujar Khan, Pakistan</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div style={{ height: "60px" }}></div>
      <Toolbar />
    </>
  );
}

export default About;
