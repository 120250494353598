import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import Home from "./Components/Home/Home";
import Search from "./Components/Search/Search";
import Details from "./Components/Details/Details";
import Category from "./Components/Category/Category";
import Pro_Page from "./Components/ProPage/ProPage";
import { Routes, Route } from "react-router-dom";
import Cart from "./Components/Cart/Cart";
import ConfirmOrder from "./Components/ConfirmOrder/ConfirmOrder";
import More from "./Components/More/More";
import EditProfile from "./Components/EditProfile/Edit_Profile";
import MyAddress from "./Components/MyAddress/MyAddress";
import OrderHistory from "./Components/Orders/OrderHistory";
import Orders from "./Components/Orders/Orders.jsx";
import Order_detail from "./Components/order_detail/order_detail.jsx";
import Login from "./Components/SignUP/Login/Login";
import OTP from "./Components/SignUP/OPTVerification/OTP";
import NewPassword from "./Components/SignUP/NewPassword/NewPassword";
import Notification from "./Components/Notification/Notification";
import AddNewAddress from "./Components/MyAddress/AddNewAddress.jsx";
import NewAcc from "./Components/SignUP/NewAcc/NewAcc";
import About from "./Components/About/About.jsx";
import Wash from "./Components/Setting/Wash.jsx";
// Rider
import RDashboard from "./Components/Rider/RDashboard/Rdashboard";
import RAllOrders from "./Components/Rider/RAllOrders/RAllOrders.jsx";
import UploadRecipt from "./Components/Rider/Upload/UploadRecipt.jsx";

import RCOrders from "./Components/Rider/RCOrders/RCOrders.jsx";
import RCDetail from "./Components/Rider/RCOrders/RCDetail.jsx";

import RPendingOrders from "./Components/Rider/RPendingOrders/RPendingOrders.jsx";
import RPODetails from "./Components/Rider/RPendingOrders/RPODetails.jsx";

import RAllCOrders from "./Components/Rider/RAllCOrders/RAllCOrders.jsx";
import RACODetail from "./Components/Rider/RAllCOrders/RAllCODetail.jsx";

//Seller
import SDashboard from "./Components/Seller/SDashboard/Sdashboard";

import SAllOrders from "./Components/Seller/SAllOrders/SAllOrders.jsx";
import SOrderDetails from "./Components/Seller/SAllOrders/SOrderDetails.jsx";

import SReadyOrders from "./Components/Seller/SReadyOrders/SReadyOrders.jsx";
import SRODetails from "./Components/Seller/SReadyOrders/SRODetails.jsx";

import SHistoryOrders from "./Components/Seller/SHistoryOrder/SHistoryOrders.jsx";
import SHODetails from "./Components/Seller/SHistoryOrder/SHODetails.jsx";

import SDailyHistory from "./Components/Seller/SDailyHistory/SDailyHistory.jsx";
import SDHDetails from "./Components/Seller/SDailyHistory/SDHDetails.jsx";

import Logout from "./Logout/logout.jsx";

import NotFound from "./Components/NotFound/NotFound";
import { Toaster } from "react-hot-toast";
function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });
  if (width > 768) {
    return <Details />;
  }
  return (
    <>
      <Routes>
        {/* Customer */}
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Search" element={<Search />} />
        <Route path="/Details" element={<Details />} />
        <Route path="/Category" element={<Category />} />
        <Route path="/Pro_Page" element={<Pro_Page />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/ConfirmOrder" element={<ConfirmOrder />} />
        <Route path="/More" element={<More />} />
        <Route path="/EditProfile" element={<EditProfile />} />
        <Route path="/MyAddress" element={<MyAddress />} />
        <Route path="/AddNewAddress" element={<AddNewAddress />} />
        <Route path="/OrderHistory" element={<OrderHistory />} />
        <Route path="/Orders" element={<Orders />} />
        <Route path="/Orderdetail" element={<Order_detail />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/NewAcc" element={<NewAcc />} />
        <Route path="/OTP" element={<OTP />} />
        <Route path="/NewPassword" element={<NewPassword />} />
        <Route path="/Notification" element={<Notification />} />
        <Route path="/About" element={<About />} />
        <Route path="/Wash" element={<Wash />} />
        <Route path="/Logout" element={<Logout />} />
        <Route path="*" element={<NotFound />} />

        {/* Rider */}
        <Route path="/RDashboard" element={<RDashboard />} />
        <Route path="/RAllOrders" element={<RAllOrders />} />
        <Route path="/UploadRecipt" element={<UploadRecipt/>}/>
        <Route path="/RCOrders" element={<RCOrders/>}/>
        <Route path="/RCDetail" element={<RCDetail/>}/>
        <Route path="/RAllCOrders" element={<RAllCOrders/>}/>
        <Route path="/RACODetail" element={<RACODetail/>}/>
        <Route path="/RPendingOrders" element={<RPendingOrders/>}/>
        <Route path="/RPODetails" element={<RPODetails/>}/>

        {/* Seller */}
        <Route path="/SDashboard" element={<SDashboard />} />
        <Route path="/SAllOrders" element={<SAllOrders />} />
        <Route path="/SOrderDetails" element={<SOrderDetails />} />
        <Route path="/SReadyOrders" element={<SReadyOrders />} />
        <Route path="/SRODetails" element={<SRODetails />} />
        <Route path="/SHistoryOrders" element={<SHistoryOrders />} />
        <Route path="/SHODetails" element={<SHODetails />} />
        <Route path="/SDailyHistory" element={<SDailyHistory />} />
        <Route path="/SDHDetails" element={<SDHDetails />} />
      </Routes>
      <Toaster position="top-center" duration={1000} />

    </>
    
  );
  
}

export default App;
