import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  faUser,
  faKey,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { APIsignUp } from "../../../api/Signup";
import toast from "react-hot-toast";
import { TbArrowBack } from "react-icons/tb";

function NewAcc() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // Refs for form inputs
  const emailRef = useRef();
  const usernameRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const navigate = useNavigate();

  // Helper function to validate email
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  // Function to check form validity
  const checkFormValidity = () => {
    const email = emailRef.current?.value || "";
    const username = usernameRef.current?.value || "";
    const password = passwordRef.current?.value || "";
    const confirmPassword = confirmPasswordRef.current?.value || "";

    const isFormValid =
      isValidEmail(email) &&
      username.trim() !== "" &&
      password.length >= 8 &&
      password === confirmPassword;

    return isFormValid;
  };

  // Function to handle signup
  const handleSignup = async (e) => {
    e.preventDefault(); // Prevent default form submission
    const email = emailRef.current?.value;
    const username = usernameRef.current?.value;
    const password = passwordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;
    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    if (username.trim() === "") {
      toast.error("Please enter a valid username");
      return;
    }
    if (password.length < 8) {
      toast.error("Password must be at least 8 characters long");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    setLoading(true);
    try {
      if (!navigator.onLine) return;
      const user = await APIsignUp(email, username, password, confirmPassword);
      if (user.message) {
        toast.success(`Signup success! `, user.message);
        setTimeout(() => {
          setLoading(false);
          navigate("/Login", {
            state: { email, username, fromPage: "NewAcc" },
          });
        }, 2000);
      } else {
        toast.error(user.error);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container-fluid mt-2" style={{ fontSize: "20px" }}>
        <Link to="/Login" className="text-decoration-none text-black">
          <TbArrowBack style={{ color: "black", fontSize: "25px" }} />
        </Link>
      </div>
      <div className="container-fluid px-0">
        {/* Lottie Animation */}
        <div className="d-flex align-items-center justify-content-center">
          <img src="/assets/logo.png" alt="logo" className="LOGO" />
        </div>

        {/* Signup Form */}
        <div className="container-fluid">
          <h4>Signup</h4>
          {/* <small>Please enter your valid email, username, and password.</small> */}
          <form onSubmit={handleSignup}>
            {/* Email Input */}
            <div className="input-group mb-2">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faGoogle} style={{ color: "#4285F4" }} />
              </span>
              <input
                type="email"
                placeholder="Enter your email"
                className="form-control outline-none shadow-none"
                required
                ref={emailRef}
                disabled={loading}
              />
            </div>

            {/* Username Input */}
            <div className="input-group mb-2">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faUser} />
              </span>
              <input
                type="text"
                placeholder="Enter your username"
                className="form-control outline-none shadow-none"
                required
                ref={usernameRef}
                disabled={loading}
              />
            </div>

            {/* Password Input */}
            <div className="input-group mb-3">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faKey} />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                className="form-control outline-none shadow-none"
                ref={passwordRef}
                required
                minLength="8"
                disabled={loading}
              />
            </div>

            {/* Confirm Password Input */}
            <div className="input-group mb-2">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faKey} />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Confirm your password"
                className="form-control shadow-none"
                ref={confirmPasswordRef}
                required
                minLength="8"
                disabled={loading}
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                disabled={loading}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            {passwordRef.current?.value?.length < 8 && (
              <small className="text-danger">
                Password length should be at least 8 characters*
              </small>
            )}

            {/* Submit Button */}
            <button
              className="Submit btn btn-primary "
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Next"
              )}
            </button>
          </form>
        </div>
      </div>
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default NewAcc;
