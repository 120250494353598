import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { readFromDB } from "../../../indexedDB";
import { TbArrowBack } from "react-icons/tb";
import RNavbar from "../Navbar/RNavbar";
import { toast } from "react-hot-toast";

function RCDetails() {
  const [orderDetail, setOrderDetail] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Extract order ID from query params
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("order_id");
  console.log(orderId);
  // Fetch order details from IndexedDB
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await readFromDB("RAO_History", "RAO");

        if (!data || data.length == 0) {
          navigate("/RAllCOrders");
          return;
        }


        const orderData = data.find((order) => order?.order_info?.order_id == orderId);


        setOrderDetail(orderData);
      } catch (error) {
        toast.error("Error in fetching data");
      }
    };

    fetchData();
  }, [orderId, navigate]);

  // If no order detail, show a loading message
  // if (!orderDetail) {
  //   return (
  //     <div className="text-center mt-5">
  //       <p>Loading order details...</p>
  //     </div>
  //   );
  // }

  // Calculate total products and total price
  const totalProducts = orderDetail?.products?.length > 0 && orderDetail?.products?.reduce((acc, curr) => acc + parseInt(curr.qty), 0);
  const totalPrice = orderDetail?.products?.length > 0 && orderDetail?.products?.reduce((acc, curr) => acc + curr.price * curr.qty, 0);

  return (
    <>
      <RNavbar />
      <div
        className="RPODetails mt-4 p-3 border rounded"
        style={{ maxWidth: "600px", backgroundColor: "#f9f9f9" }}
      >
        {/* Back Button */}
        <div className="order-header d-flex align-items-center mb-4">
          <Link to="/RCOrders" className="text-decoration-none text-black">
            <TbArrowBack style={{ fontSize: "20px" }} /> Completed Order
          </Link>
        </div>

        {/* Receipt Header */}
        <h5 className="text-center mb-3">Order Receipt</h5>
        <hr />

        {/* Order Information */}
        <div className="mb-3">
          <div className="row mb-1">
            <div className="col-6">
              <p>
                <strong>Username:</strong> {orderDetail?.user_details?.username || "N/A"}
              </p>
            </div>
            <div className="col-6">
              <p className="mb-1">
                <strong>Phone:</strong> {orderDetail?.order_info?.phone || "N/A"}
              </p>
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-6">
              <p>
                <strong>Total:</strong> {parseInt(orderDetail?.order_info?.total) - 100 || 0}
              </p>
            </div>
            <div className="col-6">
              <p>
                <strong>Delivery:</strong> {orderDetail?.order_info?.delivery_charges || 0}
              </p>
            </div>
          </div>
          <p className="mb-1">
            <strong>SubTotal:</strong> {parseInt(orderDetail?.order_info?.total) || 0}
          </p>
          <p className="mb-1">
            <strong>Address:</strong> {orderDetail?.order_info?.address || "N/A"}
          </p>
        </div>

        {/* Products Table */}
        <table className="table text-center table-text">
          <thead className="table-light">
            <tr>
              <th>Product Name</th>
              <th>Qty</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {orderDetail?.products?.length > 0 && orderDetail?.products?.map((product) => (
              <tr key={product.id}>
                <td>{product.name}</td>
                <td>
                  {product.unit_id} x {product.qty}
                </td>
                <td>{product.price * product.qty}</td>
              </tr>
            ))}
            <tr>
              <td colSpan={2} className="text-end">
                <strong>Total Products:</strong>
              </td>
              <td>
                <strong>{totalProducts || 0}</strong>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="text-end">
                <strong>Total:</strong>
              </td>
              <td>
                <strong>{totalPrice || 0}</strong>
              </td>
            </tr>
          </tbody>
        </table>

        {/* Order Status Section */}
        <div className="mt-3">
          <p className="mb-1">
            <strong>Order Status:</strong> {orderDetail?.order_info?.status || "N/A"}
          </p>
          {orderDetail?.order_info?.cancel_time && (
            <p className="mb-1">
              <strong>Cancel Time:</strong> {orderDetail?.order_info?.cancel_time}
            </p>
          )}
          {orderDetail?.order_info?.completed_time && (
            <p className="mb-1">
              <strong>Completed Time:</strong> {orderDetail?.order_info?.completed_time}
            </p>
          )}
          {orderDetail?.order_info?.ex_time && (
            <p className="mb-1">
              <strong>Expected Time:</strong> {orderDetail?.order_info?.ex_time}
            </p>
          )}
          {orderDetail?.order_info?.ex_date && (
            <p className="mb-1">
              <strong>Expected Date:</strong> {orderDetail?.order_info?.ex_date}
            </p>
          )}
          {orderDetail?.order_info?.process_time && (
            <p className="mb-1">
              <strong>Process Time:</strong> {orderDetail?.order_info?.process_time}
            </p>
          )}
          {orderDetail?.order_info?.onway_time && (
            <p className="mb-1">
              <strong>Onway Time:</strong> {orderDetail?.order_info?.onway_time}
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default RCDetails;
