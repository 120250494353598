import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import "../Navbar/Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { readFromDB } from "../../indexedDB";
import {
  faPowerOff
} from "@fortawesome/free-solid-svg-icons";const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [name, setName] = useState("name");
  const navigate = useNavigate();

  // Check login status on component mount
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const user = await readFromDB("UserStore","user"); // Fetch user details from IndexedDB
        if (user) {
          setIsLoggedIn(true);
          setName(user.username); // Assuming the user object has a "name" field
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();
  }, []);

  return (
    <div className="container p-0 mb-2">
      <nav className="navbar navbar-expand-lg p-0">
        <Link className="navbar-brand d-flex flex-fill  justify-content-center align-items-center" to="/Home">
          <img className="d-flex justify-content-center" id="logo" src="./assets/logo.png" loading="lazy" alt="logo" />
        </Link>
        {!isLoggedIn ? (
          <Link className="nav-link fs-5 fw-bold d-flex col-6 justify-content-end pe-3" to="/Login">
            Login
          </Link>
        ) : (
          <>
          </>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
