import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faKey, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import "./Email.css";
import { APIlogin } from "../../../api/signin";
import toast from "react-hot-toast"; // Toast for notifications
import { createInDB } from "../../../indexedDB"; // Import IndexedDB utility
import { TbArrowBack } from "react-icons/tb"


function Email() {
  const [showPassword, setShowPassword] = useState(false);
  const emailRef = useRef(); // Reference for email input
  const passwordRef = useRef(); // Reference for password input
  const userTypeRef = useRef(); // Reference for password input

  const [isLoading, setIsLoading] = useState(false); // State to track loading
  const navigate = useNavigate();
  


  // const isValidEmail = (email) => {
  //   const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   return emailPattern.test(email);
  // };

  const ForgetPassword = () => {
    const toPage = "NewPass";
    const email = emailRef.current.value;

    if (!email) {
      toast.error("Please enter a UserName/email!");
      return;
    }


    setIsLoading(true);
    toast.loading("Processing request...", { id: "forgot-loading" });

    setTimeout(() => {
      toast.dismiss("forgot-loading");
      setIsLoading(false);
      navigate("/OTP", { state: { email, toPage } });
    }, 1000); // Simulate an API call
  };

  const validateCredentials = async () => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const type = userTypeRef.current.value;

    if (!email || !password) {
      toast.error("Please fill in all fields!");
      return;
    }

    setIsLoading(true);
    toast.loading("Logging in...", { id: "login-loading" });

    try {
      if (!navigator.onLine) return;
      const login = await APIlogin(email, password,type);

      if (login.message) {
        // Save user data in IndexedDB instead of cookies
        const userData = {
          id: login.user.id,
          token: login.user.token,
          username: login.user.username,
          email: login.user.email,
          phone: login.user.phone,
          img: login.user.img,
          status: login.user.status
        };

        
        //User
        toast.dismiss("login-loading");
        toast.success(login.message || "Login successful!");
        setTimeout(() => {
          navigate(`/${login.user.redirect}`);
        }, 2000);
        await createInDB('UserStore', "user", userData);
      } else {
        toast.dismiss("login-loading");
        if (login.redirect == 'otp') {
          const toPage = false;
          setTimeout(() => {
            navigate("/OTP", { state: { email, toPage } });
          }, 2000);
        }else{
          toast.error(login.error || "Login failed. Please try again.");
        }
        
      }
    } catch (error) {
      toast.dismiss("login-loading");
      toast.error("An error occurred during login. Please try again.",error.message );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container-fluid mt-2" style={{ fontSize: "20px" }}>
        <Link to={"/Home"} className="text-decoration-none text-black">
          <TbArrowBack style={{ color: "black", fontSize: "25px" }} /> Back to Home
        </Link>
      </div>
      <div className="container-fluid px-0">
        <div className="d-flex align-items-center justify-content-center">
          <img
            src="/assets/logo.png"
            alt="logo"
            className="LOGO"
          />
        </div>
  
        {/* Dropdown Section */}
  
        <div className="container-fluid mt-4">
          <h4>Login</h4>
          <div className="d-flex align-items-center mb-3 justify-content-between">
            <label htmlFor="userType" className="me-2 fs-5">User Type:</label>
            <select
              id="userType"
              className="form-select shadow-none"
              style={{ width: "250px" }}
              ref={userTypeRef}
            >
              <option value="User" defaultValue={"User"}>
                User
              </option>
              <option value="seller">Seller</option>
              <option value="rider">Rider</option>
            </select>
        </div>
          {/* <p>Please enter your valid email and password</p> */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              validateCredentials();
            }}
          >
            <div className="input-group mb-3">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faGoogle} style={{ color: "#4285F4" }} />
              </span>
              <input
                type="text"
                placeholder="Email / Username"
                className="form-control shadow-none"
                ref={emailRef}
                required
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text">
                <FontAwesomeIcon icon={faKey} />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                className="form-control shadow-none"
                ref={passwordRef}
                required
                minLength="8"
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            <button
              type="submit"
              className="Submit btn btn-primary"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Login"}
            </button>
          </form>
  
          <div className="d-flex align-items-center mt-3">
            <button
              onClick={ForgetPassword}
              className="FPass"
              disabled={isLoading}
            >
              {isLoading ? "processing..." : "Forgot password?"}
            </button>
          </div>
  
          <div className="d-flex align-items-center justify-content-center mt-3">
            <Link className="text-decoration-none" to="/NewAcc">
              <button className="NewAcc" disabled={isLoading}>
                {isLoading ? "processing..." : "Create New Account"}
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default Email;
