export const APIlogin = async (email, password,type) => {
  const myHeaders = new Headers();
  myHeaders.append("app", "grocy");
  myHeaders.append("callsign", "login");
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("login", email);
  urlencoded.append("password", password);
  urlencoded.append("type", type);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
  };

  const response = await fetch("https://api.fourelementx.com", requestOptions)
    .then((response) => response?.json())
    .then(result => {
    return result
})
    .catch(error => error);

  return response;
};
