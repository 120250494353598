import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import "../ToolBar/Toolbar.css";
import { Link, useLocation } from "react-router-dom";
import { readFromDB } from "../../indexedDB";
import { CgMenuGridR } from "react-icons/cg";
import { RiMenuAddFill } from "react-icons/ri";
import { IoHome } from "react-icons/io5";
import { TfiShoppingCartFull } from "react-icons/tfi";
function Toolbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null); // Use null to represent no user
  const [active, setActive] = useState("");
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const fetchedUser = await readFromDB("UserStore", "user");
      setUser(fetchedUser);
    })();
  }, []);

  useEffect(() => {
    setIsLoggedIn(!!user); // Converts the user object to a boolean
  }, [user]);

  // Update active state based on location pathname
  useEffect(() => {
    const path = location.pathname.replace("/", ""); // Remove leading slash
    setActive(path || "Home"); // Default to 'Home' if path is empty
  }, [location]);

  return (
    <div className="container toolbar d-flex justify-content-center gap-5">
      <Link to="/Home">
        <button
          className={`toolbar-button ${active === "Home" ? "active" : ""}`}
          onClick={() => setActive("Home")}
        >
          <IoHome  className="toolbar-icon" />
        </button>
      </Link>

      <Link to="/Category">
        <button
          className={`toolbar-button ${active === "Category" ? "active" : ""}`}
          onClick={() => setActive("Category")}
        >
          <CgMenuGridR className="toolbar-icon GridIcon" />
        </button>
      </Link>

      <Link
        to="/Cart"
        style={{
          pointerEvents: isLoggedIn ? "auto" : "none",
          opacity: isLoggedIn ? 1 : 0.3,
        }}
      >
        <button
          className={`toolbar-button ${active === "Cart" ? "active" : ""}`}
          onClick={() => {
            setActive("Cart");
            window.scrollTo(0, 0);
          }}
        >
          <TfiShoppingCartFull className="toolbar-icon " />
        </button>
      </Link>

      <Link
        to="/More"
        style={{
          pointerEvents: isLoggedIn ? "auto" : "none",
          opacity: isLoggedIn ? 1 : 0.3,
        }}
      >
        <button
          className={`toolbar-button ${active === "More" ? "active" : ""}`}
          onClick={() => setActive("More")}
        >
          <RiMenuAddFill className="toolbar-icon MoreIcon" />
        </button>
      </Link>
    </div>
  );
}

export default Toolbar;
