import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { LogOut } from "../indexedDB"; // Import the deleteFromDB function
import { readFromDB } from "../indexedDB";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const logoutUser = async () => {
      const user = await readFromDB("UserStore", "user");
      if (user) {
        await LogOut("UserStore", "user"); // This deletes the user data from IndexedDB
        if (user.status == "rider") {
          await LogOut("RAO_History", "RAO");
          await LogOut("rp_orders", "orders");
        }
        if (user.status == "seller") {
          await LogOut("sAllOrders", "SAO");
          await LogOut("sDHistoryOrders", "SDHO");
          await LogOut("sHistoryOrders", "SHO");
          await LogOut("sReadyOrders", "SRO");
        }
        // Navigate to the Email login page after deleting user data
        navigate("/Login");

        setTimeout(() => {
          toast.success("Logged out successfully!");
        }, 500);
      } else {
        navigate("/Login");
      }
    };

    logoutUser();
  }, [navigate]); // Runs once when the component mounts

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <h3>Logging out...</h3>
    </div>
  );
}

export default Logout;
