import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import OrderCard from "../Cards/OrderCard/OrderCard";
import "./Orders.css";
import { APIOrder } from "../../api/Order";
import { useEffect, useState } from "react";
import { readFromDB, createInDB } from "../../indexedDB";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
function OrderHistory() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const data = await readFromDB("Order", "orders");
        if (Array.isArray(data)) {
          setData(data);
        } else {
          const user = await readFromDB("UserStore", "user"); // Fetch user details from IndexedDB
          const dataFromAPI = await APIOrder(user.id);
          console.log(dataFromAPI);
          if (Array.isArray(dataFromAPI)) {
            setData(dataFromAPI);
            await createInDB("Order", "orders", dataFromAPI); // Save to IndexedDB
          }
        }
      } catch (error) {
        toast.error("Error in fetchOrders");
      }
    };

    fetchOrders();
  }, []);

  return (
    <>
      <Navbar />
      {/* section 1 */}
      <div className="container-fluid px-0 mt-2 mb-2">
        <div className="row">
          <div className="col-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link id="link" to="/More">
                  More
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/Orders">
                  My Orders
                </Link>
              </li>
            </ol>
          </div>
        </div>
        {/* Section 2 */}
        <div className="row d-flex">
          <div className="col-6">
            <Link className="text-decoration-none" to="/Orders">
              <button className="OrderType align-items-center justify-content-center">
                Order
              </button>
            </Link>
          </div>
          <div className="col-6">
            <button className="OrderType align-items-center justify-content-center">
              History
            </button>
          </div>
        </div>
        <hr />
        {/* section 3 */}
        {data.length == 0 ? (
          <div className="OrderGIFContainer text-center">
            <lottie-player
              src="https://lottie.host/f14dbc81-7b7c-453e-8132-e0551c85da37/jeZbPAbV28.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px" }}
              loop={false}
              autoplay
            ></lottie-player>
            <h4 className="text-warning fw-bold">No Orders Yet</h4>
            <p className="text-muted">
              Looks like you haven’t placed any orders. Start exploring and grab
              your favorites now!
            </p>
          </div>
        ) : (
          data
            .filter(
              (order) =>
                order.status &&
                ["cancelled", "completed", "rejected"].includes(
                  order.status.toLowerCase()
                )
            )
            .sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
            .map((order, index) => (
              <div
                key={order.order_id || `order-${index}`} // Avoid Math.random
                onClick={() => {
                  navigate(`/OrderDetail?order_id=${order.order_id}`);
                }}
              >
                <OrderCard
                  key={order.order_id || Math.random()} // Use fallback key if order_id is missing
                  O_No={order.order_id || "Unknown"}
                  O_State={order.status || "Unknown"}
                  O_Date={
                    order.created_at
                      ? `${order.created_at.split(" ")[0]} (${
                          order.created_at.split(" ")[1]
                        })`
                      : "No Date"
                  }
                  O_Price={order.total || "0.00"}
                />
              </div>
            ))
        )}
      </div>
      <Toolbar />
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default OrderHistory;
