import React, { useState, useEffect } from "react";
import "./UploadRecipt.css"; // Import the CSS file
import RNavbar from "../Navbar/RNavbar";
import toast from "react-hot-toast"; // Toast for notifications
import { Link } from "react-router-dom";
import { TbArrowBack } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { APIUploadBilling } from "../../../api/UploadBilling";
import { useNavigate } from "react-router-dom";
import { readFromDB } from "../../../indexedDB";

function UploadRecipt() {
  const [image, setImage] = useState(null);
  const [paymentType, setPaymentType] = useState("cash");
  const [isEasypaisa, setIsEasypaisa] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const orderId = params.get("order_id");
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handlePaymentTypeChange = (e) => {
    const value = e.target.value;
    setPaymentType(value);
    setIsEasypaisa(value == "easypaisa"); // Toggle Easypaisa mode
  };

  // Handle next button click
  const handleNextButtonClick = async (e) => {
    e.preventDefault();
    const IMG = paymentType == "cash" ? null : document.getElementById("uploadedImage").files[0];

    if (paymentType == "easypaisa" && !IMG) {
      toast.error("Please upload the receipt image.");
      return;
    }

    try {
      const User = await readFromDB("UserStore", "user");
      APIUploadBilling(User.id, orderId, paymentType, IMG, (res) => {
        if (res.status==200) {
          toast.success("Successfully uploaded the receipt.");
          navigate("/RDashboard");
        } else {
          toast.error("An error occurred. Please try again.");
        }
      });
    } catch (error) {
      console.log("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <RNavbar />
      <div className="d-flex align-items-center mb-3">
        <Link to="/RDashboard" className="text-decoration-none text-black">
          <TbArrowBack style={{ fontSize: "25px" }} /> Order Detail 
        </Link>
      </div>

      <div className="container recipt-container">
        <h2 className="text-primary mb-4">Upload Receipt</h2>

        {/* Payment Method Dropdown */}
        <select
          className="form-select mb-3"
          value={paymentType}
          onChange={handlePaymentTypeChange}
        >
          <option value="cash">Cash</option>
          <option value="easypaisa">Easypaisa</option>
        </select>

        {/* Image Upload Input - Shown only if Easypaisa is selected */}
        <form
            method="post"
            encType="multipart/form-data"
            onSubmit={handleNextButtonClick}
          >
        {isEasypaisa && (
          <>
            <input
              id="uploadedImage"
              type="file"
              name="image"
              accept="image/*"
              className="form-control mb-3"
              onChange={handleImageChange}
            />
            {image && (
              <div className="recipt-preview">
                <img
                  src={image}
                  alt="Uploaded"
                  className="image-preview mb-4"
                />
              </div>
            )}
          </>
        )}
          <button
            type="submit"
            name="comp_status"
            className="btn btn-success"
            disabled={isLoading}
          >
            {isLoading ? <div className="loader"></div> : "Next"}
          </button>
        </form>
      </div>
    </>
  );
}

export default UploadRecipt;

