import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import "./Order2.css";
import { APIOrder } from "../../api/Order";
import { useEffect, useState } from "react";
import { readFromDB, createInDB } from "../../indexedDB";
import OrderCard from "../Cards/OrderCard/OrderCard";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { APICancelOrder } from "../../api/CancelOrder";

function Orders() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const fetchOrders = async () => {
    try {
      const user = await readFromDB("UserStore", "user"); // Fetch user details from IndexedDB
      const dataFromAPI = await APIOrder(user.id);
      if (Array.isArray(dataFromAPI)) {
        setData(dataFromAPI);
        await createInDB("Order", "orders", dataFromAPI); // Save to IndexedDB
      }
    } catch (error) {
      toast.error("Error in fetchOrders");
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleCancel = async (id) => {
    try {
      const res = await APICancelOrder(id);
      if (res.message) {
        toast.success(res.message);
        fetchOrders();
//        navigate("/Orders");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <Navbar />
      {/* Section 1 */}
      <div className="container-fluid px-0 mt-2 mb-2">
        <div className="row ">
          <div className="col-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link id="link" to="/More">
                  More
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/Orders">
                  My Orders
                </Link>
              </li>
            </ol>
          </div>
        </div>
        {/* Section 2 */}
        <div className="row d-flex">
          <div className="col-6">
            <Link className="text-decoration-none" to="">
              <button className="OrderType align-items-center justify-content-center">
                Order
              </button>
            </Link>
          </div>
          <div className="col-6">
            <Link className="text-decoration-none" to="/OrderHistory">
              <button className="OrderType align-items-center justify-content-center">
                History
              </button>
            </Link>
          </div>
        </div>
        <hr />
        {/* Section 3 */}
        {!data.length ||
        data.every(
          (order) =>
            order.status &&
            ["cancelled", "completed", "rejected"].includes(
              order.status.toLowerCase()
            )
        ) ? (
          <div className="OrderGIFContainer text-center">
            <lottie-player
              src="https://lottie.host/f14dbc81-7b7c-453e-8132-e0551c85da37/jeZbPAbV28.json"
              background="transparent"
              speed="1"
              style={{ width: "300px", height: "300px" }}
              autoplay
            ></lottie-player>
            <h4 className="text-warning fw-bold">No Orders Yet</h4>
            <p className="text-muted">
              Looks like you haven’t placed any orders. Start exploring and grab
              your favorites now!
            </p>
          </div>
        ) : (
          data
            .filter(
              (order) =>
                order.status &&
                !["cancelled", "completed", "rejected"].includes(
                  order.status.toLowerCase()
                )
            )
            .sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
            .map((order, index) => (
              <>
                <div>
                  {order.status.toLowerCase() === "que" && (
                    <div className=" d-flex align-items-center justify-content-end mx-4 position-relative ">
                      <button
                        type="button"
                        className="cancelbtn"
                        data-bs-toggle="modal"
                        data-bs-target={`#exampleModal${order.order_id}`}
                      >
                        Cancel Order
                      </button>
                    </div>
                  )}
                </div>
                {/* -- Modal -- */}
                <div
                  className="modal fade"
                  id={`exampleModal${order.order_id}`}
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        Are you sure you want to cancel this order?
                      </div>
                      <div className="modal-footer d-flex justify-content-center">
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => handleCancel(order.order_id)}
                          data-bs-dismiss="modal"
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  key={order.order_id || `order-${index}`} // Avoid Math.random
                  onClick={() => {
                    navigate(`/OrderDetail?order_id=${order.order_id}`);
                  }}
                  style={{ cursor: "pointer", zIndex: 100 }}
                >
                  <OrderCard
                    key={order.order_id || Math.random()} // Use fallback key if order_id is missing
                    O_No={order.order_id || "Unknown"}
                    O_State={order.status || "Unknown"}
                    O_Date={
                      order.created_at
                        ? `${order.created_at.split(" ")[0]} (${
                            order.created_at.split(" ")[1]
                          })`
                        : "No Date"
                    }
                    O_Price={order.total || "0.00"}
                    onCancel={() => handleCancel(order.order_id)}
                  />
                </div>
              </>
            ))
        )}
      </div>
      <Toolbar />
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default Orders;

