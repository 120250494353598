import React, { useState, useEffect } from "react";
import "../Home/Home.css";
import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import SearchCard from "../Cards/SearchCard/SearchCard";
import { Carousel } from "react-bootstrap";
import { createInDB,readFromDB } from "../../indexedDB"; // Import IndexedDB utility
import { FaSearch } from "react-icons/fa";
import toast from "react-hot-toast";
import {APIProductAll} from "../../api/ProductAPI";
import { APICategoryAll } from "../../api/CategoryAll";
import { APIAddress } from "../../api/getAddress";
import { APIOrder } from "../../api/Order";
import {APIBanner} from "../../api/Banner";
import { APISetting } from "../../api/Setting"; 
function Home() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [User, setUser] = useState([]);
  const [BData, setBData] = useState([]);
  const navigate = useNavigate();



  useEffect(() => {
    const checkUserStatus = async () => {
      const user = await readFromDB("UserStore", "user");
      if (user) {
        if (user.status == "rider") {
          navigate("/rdashboard");
        } else if (user.status == "seller") {
          navigate("/sdashboard");
        }  else {
          //          navigate("/login");
          navigate("/Home");
        }
        setIsLoggedIn(true);
        setUser(user);
      } else {
        setIsLoggedIn(false);
//        navigate("/login");
      }
    };
    checkUserStatus();
  }, []);
  const handleAddToCart = async (pro_id) => {
    try {
      const user = await readFromDB("UserStore", "user"); // Fetch user details from IndexedDB
      const existingCart = await readFromDB("Cart", "cart");
      const existingQty = await readFromDB("Cart", "qty");

      if (user ) {
        setIsLoggedIn(true);
        let cartToUpdate = existingCart == null ? [] : existingCart;
        let qtyToUpdate =
          existingQty == null
            ? []
            : Array.isArray(existingQty)
            ? existingQty
            : Object.values(existingQty);

        let isProductInCart = false;
        cartToUpdate.forEach((element, index) => {
          if (element == pro_id) {
            toast.error("Product already exists in the cart!");
            isProductInCart = true;
          }
        });
        if (!isProductInCart) {
          //ok state
          let productToAdd = false;
          products.forEach((element, index) => {
            if (element.id == pro_id) {
              productToAdd = true;
            }
          });
          if (!productToAdd) {
            toast.error(`Product not found!`);
          } else {
            cartToUpdate.push(pro_id); // Add product to cart
            qtyToUpdate.push(1);
            await createInDB("Cart", "cart", cartToUpdate);
            await createInDB("Cart", "qty", qtyToUpdate);
            toast.success("Product added to cart successfully!");
          }
        }
      }
else {
        toast.error("Please login first!");
        navigate("/Login");
      }
    } catch (error) {
      toast.error("Failed to add product to cart.");
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let storedProducts = await readFromDB("Products", "products");
        if (Array.isArray(storedProducts)) {
          setProducts(storedProducts);
        }
        let storedBanners = await readFromDB("Banner", "banners");
          setBData(storedBanners);
          
          let set = await readFromDB("Setting", "settings");
          if(!set ) {
            set = {token: 1};
            await createInDB("Setting", "settings", set);
          }

          let setting = await APISetting(set);
          if(setting.message) {
            if(setting.message != "updated") {
              navigate(`/wash?token=${setting.token}`);
            }
            
          }
          await createInDB("Setting", "settings", setting.token);
          

          
          let data = await APIProductAll();
          if (Array.isArray(data)) {
            setProducts(data);
            await createInDB("Products", "products", data);
          }
          let Ddata = await APIBanner();
          setBData(Ddata);
          await createInDB("Banner", "banners", Ddata);
          let categoryData = await APICategoryAll();
          if (Array.isArray(categoryData)) {
            await createInDB("Category", "category", categoryData);
          }
      } catch (error) {
        toast.error("Error Occured");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);
  useEffect(() => {
    const fetchUserAddress = async () => {
        try {
          const user = await readFromDB("UserStore", "user");
          setUser(user);
          const data = await APIAddress(user.id);
          if(Array.isArray(data)){

          await createInDB("Address", "address", data); // Save to IndexedDB
          }
        } catch (error) {          
      };
    };
    fetchUserAddress();
  }, []);

  useEffect(() => {
    const fetchUserOrders = async () => {
      try {
        const user = await readFromDB("UserStore", "user");
        if(user){
          const data = await APIOrder(user.id);
          if(Array.isArray(data)){
            await createInDB("Order", "orders", data); // Save to IndexedDB
          }
        }
      } catch (error) {          
      };
    };
    fetchUserOrders();
  })
  return (
    <>
      <Navbar />

      <div className="container-fluid px-0">
        {/* Banner Section */}
        {BData  &&  <Carousel interval={2000}>
          { BData.map((image, index) => (
            <Carousel.Item key={index + 1}>
              <img
                className="banner d-block w-100"
                src={image.img}
                alt={`Banner ${index + 1}`}
                loading="lazy"
              />
            </Carousel.Item>
          ))}
        </Carousel>}

        {/* Search Section */}
        <div className="search mt-3">
          <div className="input-group mb-1">
            <input
              type="text"
              className="SearchInput form-control shadow-none"
              placeholder="Search"
              aria-label="Search"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const searchTerm = e.target.value.toLowerCase();
                  if (searchTerm) {
                    navigate(`/Search?searchTerm=${searchTerm}`);
                  }
                }
              }}
            />
            <button
              className="btn btn-success"
              type="button"
              onClick={() => {
                const searchTerm = document.querySelector('.SearchInput').value.toLowerCase();
                if (searchTerm) {
                  navigate(`/Search?searchTerm=${searchTerm}`);
                }
              }}
            >
            <FaSearch />
                        </button>
          </div>
        </div>




        {/* Products Section */}
        <div className="container-fluid">
          <div className="row">
            {products.map((item) => (
              <div key={item.id} className="col-6 mt-3 ProCard">
                {/* <img src="./assets/CBG.png" className="lineart" alt="" />
                <img src="./assets/CBG.png" className="lineart2" alt="" /> */}

                <Link
                  to={`/Details?product_id=${item.id}`}
                  style={{ textDecoration: "none" }}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <div className="PCard p-1 justify-content-center">
                    <img
                      className=""
                      src={
                        item.img[0]
                      }
                      alt={`${item.name}`}
                      loading="lazy"

                    />
                    
                    <div className="PName">{item.name}</div>
                    <div className="PPrice d-flex px-2 py-2">
                      <strong >{item.unit_name}</strong>
                      <span className="text-success">{item.price}.Rs</span>
                      </div>

                  </div>
                </Link>
                <div className="ProductCardButton">
                  {/* <img src="./assets/btnbg.png" className="btnArt" alt="" />
                  <img src="./assets/btnbg.png" className="btnArt2" alt="" /> */}
                  <button className="CB" onClick={() => handleAddToCart(parseInt(item.id))}>Add To Bag</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <dotlottie-player
            src="https://lottie.host/2d18ea19-5c66-4a0f-9f1f-53684d481a7d/4EN0VWA9OY.json"
            background="transparent"
            speed="1"
            style={{ width: "1px", height: "1px" }}
            direction="1"
            playMode="normal"
            loop
            autoplay
          ></dotlottie-player>
      <Toolbar />
      <div style={{ height: "60px" }}></div>

    </>
  );
}

export default Home;