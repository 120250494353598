export const APIUploadBilling = async (rider_id,order_id,paid_by,img,callback) => {
    const myHeaders = new Headers();
    myHeaders.append("app", "grocy");
    myHeaders.append("callsign", "rider");
    // myHeaders.append("Content-Type", "application/multipart/form-data");
    
    const formData = new FormData();
    formData.append("comp_status", true);
    formData.append("order_id", order_id);
    formData.append("rider_id", rider_id);
    formData.append("paid_by", paid_by);
    formData.append("img", img);
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
    };
    
    const response = await fetch("https://api.fourelementx.com", requestOptions)
      .then(response => {
        console.log(response);
        response.json();
        callback (response);
      })
      .catch(error => {

        console.error(error);
        callback (error);
      });
  };
