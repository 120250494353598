export const APIBanner = async () => {
    const myHeaders = new Headers();
    myHeaders.append("app", "grocy");
    myHeaders.append("callsign", "banner");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    const urlencoded = new URLSearchParams();
    urlencoded.append("getall", "true");
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };
    
    const response = await fetch("https://api.fourelementx.com", requestOptions)
      .then(response => response.json())
      .catch(error => {
        console.error(error);
        return error;
      });
    return response;
  };
  