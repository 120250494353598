import SNavbar from "../SNavbar/SNavbar";
import "./Sdashboard.css";
import { AiOutlineOrderedList } from "react-icons/ai";
import { GoChecklist } from "react-icons/go";
import { GrHistory } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { readFromDB } from "../../../indexedDB";
import { useEffect, useState } from "react";
import { FaFacebook } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io5";
import { CiTwitter } from "react-icons/ci";
import { RiLogoutCircleLine } from "react-icons/ri";
import { FaClock, FaHistory } from "react-icons/fa";
import { IoMdDoneAll } from "react-icons/io";
import { APISChkStatus } from "../../../api/Seller/CheckStatus";
import { APISStatus } from "../../../api/Seller/ActiveStatus";
import toast from "react-hot-toast";
import { useRef } from "react";
function SDashboard() {
  let [status, setStatus] = useState();
  const navigate = useNavigate();
  const [User, setUser] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const user = await readFromDB("UserStore", "user");
      if (user && user.status == "seller") {
        setUser(user);
        try {
          await APISChkStatus(user.id, (result) => {
            if (result) {
              console.log(result);
              setStatus(result.current_status);
            }
          });
        } catch (error) {}
      } else {
        navigate("/Login");
      }
    };
    fetchData();
  }, []);
  const handleStatus = async (e) => {
    const newStatus = e.target.checked;
    const res = await APISStatus(User.id, newStatus);
    if (res.message) {
      toast.success(res.message);
    } else {
      toast.error(res.error);
    }

    setStatus(newStatus);
  };

  return (
    <>
      <SNavbar />
      <div className="container">
        <div className="row mx-4 d-flex justify-content-between align-items-center">
          <h2 className="rider-dashboard">Seller Dashboard</h2>
          <div className="d-flex justify-content-between ">
            {User && (
              <>
                <span>
                  <span className="fw-bold">User Name:</span> {User?.username}
                </span>
                <span>
                  <span className="fw-bold">Phone: </span>
                  {User?.phone}
                </span>
              </>
            )}
          </div>
          <div className="toggler">
            <input
              id="toggler-1"
              name="toggler-1"
              type="checkbox"
              value={status}
              checked={status}
              onChange={handleStatus}
            />
            <label htmlFor="toggler-1">
              <svg
                className="toggler-on"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 130.2 130.2"
              >
                <polyline
                  className="path check"
                  points="100.2,40.2 51.5,88.8 29.8,67.5"
                ></polyline>
              </svg>
              <svg
                className="toggler-off"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 130.2 130.2"
              >
                <line
                  className="path line"
                  x1="34.4"
                  y1="34.4"
                  x2="95.8"
                  y2="95.8"
                ></line>
                <line
                  className="path line"
                  x1="95.8"
                  y1="34.4"
                  x2="34.4"
                  y2="95.8"
                ></line>
              </svg>
            </label>
          </div>
        </div>

        <div className="RDash1 py-2">
          <div className="row mx-4 d-flex justify-content-center align-items-center text-center">
            <div
              className="col-4 col-sm-6 mb-2"
              onClick={() => navigate("/SAllOrders")}
            >
              <button className=" btn RDash1btn">
                <AiOutlineOrderedList />
              </button>
              <br />
              All orders
            </div>
            <div className="col-4 col-sm-6 mb-2">
              <button
                className=" btn RDash1btn"
                onClick={() => navigate("/SReadyOrders")}
              >
                <IoMdDoneAll />
              </button>
              <br />
              Ready
            </div>
            <div
              className="col-4 col-sm-6 mb-2"
              onClick={() => navigate("/SDailyHistory")}
            >
              <button className=" btn RDash1btn fs-5">
                <GoChecklist />
              </button>
              <br />
              Completed
            </div>

            <div className="col-4 col-sm-6">
              <button
                className=" btn RDash1btn"
                onClick={() => navigate("/SHistoryOrders")}
              >
                <GrHistory />{" "}
              </button>
              <br />
              History{" "}
            </div>

            <div className="col-4 col-sm-6">
              <button
                className=" btn RDash1btn"
                onClick={() => navigate("/Logout")}
              >
                <RiLogoutCircleLine />
              </button>
              <br />
              Logout{" "}
            </div>
          </div>
        </div>

        {/* Section 2 */}
        <div className="row RDash2 mx-1 mt-3 d-flex justify-content-center align-items-center text-center">
          {/* Order Completed */}
          <div className="col-sm-6 my-3">
            <div className="card Rcomp">
              <div className="card-body">
                <GoChecklist
                  size={40}
                  color="#fff"
                  onClick={() => navigate("/SDailyHistory")}
                />
                <h5>Order Completed</h5>
                {/* <p>{COrders.length} Orders</p> */}
              </div>
            </div>
          </div>

          {/* Pending */}
          <div className="col-6 ">
            <div className="card Rpending">
              <div
                className="card-body"
                onClick={() => navigate("/SAllOrders")}
              >
                <FaClock size={40} color="#fff" />
                <h5>All Orders</h5>

                {/* <p>{POrders.length} Orders</p> */}
              </div>
            </div>
          </div>

          {/* Payment */}
          <div className="col-6 ">
            <div className="card Rpayment">
              <div
                className="card-body"
                onClick={() => navigate("/SReadyOrders")}
              >
                <IoMdDoneAll size={40} color="#fff" />
                <h5>Ready</h5>
                {/* <p><span className="fw-bold">Rs.</span> {RDailyTotal}</p> */}
              </div>
            </div>
          </div>

          {/* Order History */}
          <div
            className="col-sm-6 my-3"
            onClick={() => navigate("/SHistoryOrders")}
          >
            <div className="card ROH">
              <div className="card-body">
                <FaHistory size={40} color="#fff" />
                <h5>Order History</h5>
                <p>View All</p>
              </div>
            </div>
          </div>
        </div>

        {/* Section 3 */}
        {/* <div className="row RDash3 mx-2 d-flex justify-content-center align-items-center text-center">
          <div className="col-md-8">
            <h4 className="mb-4">Orders Over Time</h4>
            <Bar data={data} options={options} />
          </div>
        </div> */}
      </div>
      <hr />
      <footer className="app-footer text-center">
        <div className="footer-content">
          <p>
            &copy; {new Date().getFullYear()} Grocy App. All rights reserved.
          </p>
          <div className="social-icons">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CiTwitter />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoLogoInstagram />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}
export default SDashboard;
