export const APIRAllOrders = async (rider_id) => {
    const myHeaders = new Headers();
    myHeaders.append("app", "grocy");
    myHeaders.append("callsign", "rider");
    // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
    const urlencoded = new URLSearchParams();
    
    urlencoded.append("getall", true);
    urlencoded.append("rider_id", rider_id);

  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };
  
    const response = await fetch("https://api.fourelementx.com", requestOptions)
      .then((response) => response?.json())
      .then(result => {
      return result
  })
      .catch(error => error);
  
    return response;
  };