import RNavbar from "../Navbar/RNavbar";
import "./Rdashboard.css";
import { AiOutlineOrderedList } from "react-icons/ai";
import { GoChecklist } from "react-icons/go";
import { MdOutlinePending } from "react-icons/md";
import { GrHistory } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { readFromDB } from "../../../indexedDB";
import { useEffect, useState } from "react";
import {toast} from "react-hot-toast";
import { FaFacebook } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io5";
import { CiTwitter } from "react-icons/ci";
import { RiLogoutCircleLine } from "react-icons/ri";
import {  FaCheckCircle,  FaClock,  FaMoneyCheckAlt,  FaHistory,
} from "react-icons/fa";
import { APIRDailyTotal } from "../../../api/RDailyTotal";

function RDashboard() {
  
  const [POrders, setPOrders] = useState([]);
  const [COrders, setCOrders] = useState([]);
  const [RDailyTotal, setRDailyTotal] = useState([]);
  const navigate = useNavigate();
  const [User, setUser] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const user = await readFromDB("UserStore", "user");
      if (user && user.status == "rider") {
        console.log("Fetched user:", user); // Logs the fetched user immediately
        setUser(user);
      } else {
        navigate("/Login");
      }
    };
    fetchData();
  }, []);
  
  
  useEffect(() => {
    const fetchData = async () => {
      const user = await readFromDB("UserStore", "user");
      try {
        await APIRDailyTotal(user.id, (res) => {
          if (res.message) {
            setRDailyTotal(res.message);

          } else {
//            toast.error(res.error);
          }
        });
      } catch (error) {
//        toast.error(error.message);
      }
    };
    fetchData();
  }, []);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await readFromDB("rp_orders", "orders");

        if (data) {
          setPOrders(data);
        }

        const data2 = await readFromDB("RAO_History", "RAO");

        if (data2) {
          setCOrders(data2);
        }
      } catch (error) {
//        toast.error("Error fetching pending orders");
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <RNavbar />
      <div className="container">
        <div className="row mx-1 d-flex justify-content-between align-items-center">
          <h2 className="rider-dashboard">Rider Dashboard</h2>
          <div className="d-flex justify-content-between ">
            {User ? (
              <>
                <span><span className="fw-bold">User Name:</span> {User.username}</span>
                <span><span className="fw-bold">Phone: </span>{User.phone}</span>
              </>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div className="RDash1 py-2">
          <div className="row mx-4 d-flex justify-content-center align-items-center text-center">
            <div className="col-4 col-sm-6 mb-2" onClick={() => navigate("/RAllOrders")}>
              <button className=" btn RDash1btn" >
                <AiOutlineOrderedList />
              </button>
              <br />
              All orders
            </div>
            <div className="col-4 col-sm-6 mb-2">
              <button className=" btn RDash1btn" onClick={() => navigate("/RPendingOrders")}>
                <MdOutlinePending />
              </button>
              <br />
              Pending
            </div>
            <div className="col-4 col-sm-6 mb-2" onClick={() => navigate("/RCOrders")}>
              <button className=" btn RDash1btn fs-5">
                <GoChecklist />
              </button>
              <br />
              Completed
            </div>

            <div className="col-4 col-sm-6">
              <button className=" btn RDash1btn"  onClick={() => navigate("/RAllCOrders")}>
                <GrHistory />{" "}
              </button>
              <br />
              History{" "}
            </div>

            <div className="col-4 col-sm-6">
              <button className=" btn RDash1btn" onClick={() => navigate("/Logout")}>
              <RiLogoutCircleLine />
              </button>
              <br />
              Logout{" "}
            </div>
          </div>
        </div>

        {/* Section 2 */}
        <div className="row RDash2 mx-1 mt-3 d-flex justify-content-center align-items-center text-center">
          {/* Order Completed */}
          <div className="col-sm-6 my-3">
            <div className="card Rcomp">
              <div className="card-body">
                <FaCheckCircle size={40} color="#fff" onClick={() => navigate("/RCOrders")}/>
                <h5>Order Completed</h5>
                <p>{COrders.length} Orders</p>
              </div>
            </div>
          </div>

          {/* Pending */}
          <div className="col-6 ">
            <div className="card Rpending">
              <div className="card-body" onClick={() => navigate("/RPendingOrders")}>
                <FaClock size={40} color="#fff" />
                <h5>Pending</h5>

                <p>{POrders.length} Orders</p>
              </div>
            </div>
          </div>

          {/* Payment */}
          <div className="col-6 ">
            <div className="card Rpayment">
              <div className="card-body">
                <FaMoneyCheckAlt size={40} color="#fff" />
                <h5>Payment</h5>
                <p><span className="fw-bold">Rs.</span> {RDailyTotal}</p>
              </div>
            </div>
          </div>

          {/* Order History */}
          <div className="col-sm-6 my-3" onClick={() => navigate("/RAllCOrders")}>
            <div className="card ROH">
              <div className="card-body">
                <FaHistory size={40} color="#fff" />
                <h5>Order History</h5>
                <p>View All</p>
              </div>
            </div>
          </div>
        </div>

        {/* Section 3 */}
        {/* <div className="row RDash3 mx-2 d-flex justify-content-center align-items-center text-center">
          <div className="col-md-8">
            <h4 className="mb-4">Orders Over Time</h4>
            <Bar data={data} options={options} />
          </div>
        </div> */}
      </div>
      <hr/>
      <footer className="app-footer text-center">
        <div className="footer-content">
          <p>&copy; {new Date().getFullYear()} Grocy App. All rights reserved.</p>
          <div className="social-icons">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <CiTwitter />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <IoLogoInstagram />
            </a>
          </div>
        </div>
      </footer>

    </>
  );
}
export default RDashboard;
