import Navbar from "../Navbar/Navbar";
import {
  faPencil,
  faLocationDot,
  faCartShopping,
  faPhone,
  faPowerOff,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import { GrInfo } from "react-icons/gr";
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import "./More.css";
import ProfileListCard from "../Cards/ProfileListCard/ProfileListCard";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { readFromDB } from "../../indexedDB";

const More = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState(null); // Use null to represent no user

  useEffect(() => {
    (async () => {
      const fetchedUser = await readFromDB("UserStore", "user");
      if (!fetchedUser) {
        navigate("/Login");
      }

      setUser(fetchedUser);
    })();
  }, []);

  useEffect(() => {
    setIsLoggedIn(!!user); // Converts the user object to a boolean
  }, [user]);

  return (
    <>
      <Navbar />
      {!isLoggedIn ? (
        <div className="text-center mt-4">
          <p>Please log in first.</p>
          <Link to="/Login" className="btn btn-primary">
            Go to Login
          </Link>
        </div>
      ) : (
        <div className="container-fluid px-0">
          <div className="row mt-2">
            <div className="col-10">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link id="link" to="/Home">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link id="link" to="/More">
                    More
                  </Link>
                </li>
              </ol>
            </div>
          </div>

          {/* Section 2 */}
          <div className="row align-items-center Profile">
            <div className="col-1 ProfilePic">
              <img src={user?.img} loading="lazy" alt="Profile" />
            </div>
            <div className="col-2"></div>
            <div className="col-8 about">
              <div className="UserName">{user?.username}</div>
              <div className="Phno">{user?.phone}</div>
            </div>
          </div>

          {/* Section 3 */}
          <Link
            className="bg-Click"
            to="/EditProfile"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ProfileListCard
              icon={faPencil}
              list_Name={"Edit Profile"}
              IC={"#4A90E2"}
            />
          </Link>
          <Link
            className="bg-Click"
            to={"/MyAddress"}
            style={{ textDecoration: "none", color: "black" }}
          >
            <ProfileListCard
              icon={faLocationDot}
              list_Name={"My Address"}
              IC={"purple"}
            />
          </Link>
          <Link
            to={"/Orders"}
            style={{ textDecoration: "none", color: "black" }}
          >
            <ProfileListCard
              icon={faCartShopping}
              list_Name={"My Orders"}
              IC={"#DB55F0"}
            />
          </Link>
          <Link
            to="https://wa.me/+923125659460"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ProfileListCard
              icon={faPhone}
              list_Name={"Talk to our Support"}
              IC={"orange"}
            />
          </Link>

          <Link to="/About" style={{ textDecoration: "none", color: "black" }}>
            <ProfileListCard
              icon={faLightbulb}
              list_Name={"About"}
              IC={"green"}
            />
          </Link>
          <Link to="/Logout" style={{ textDecoration: "none", color: "black" }}>
            <ProfileListCard
              icon={faPowerOff}
              list_Name={"Log out"}
              IC={"red"}
            />
          </Link>
        </div>
      )}
      <Toolbar />
      <div style={{ height: "60px" }}></div>
    </>
  );
};

export default More;
