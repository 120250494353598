import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/Home.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import C_Card from "../Cards/C_Card/C_Card";
import Toolbar from "../ToolBar/Toolbar";
import { APICategoryAll } from "../../api/CategoryAll";
import { useState, useEffect } from "react";
import { createInDB,readFromDB } from "../../indexedDB"; // Import IndexedDB utility
import toast from "react-hot-toast";

const Store_category = "Category"; // Table name

function Category() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // Step 1: Try to fetch all categories from IndexedDB
        const storedCategories = await readFromDB(Store_category,"category");
        if (storedCategories) {
          
          setCategories(storedCategories);
          setLoading(false);
          return;
        }
      } catch (error) {
        toast.error("Error fetching categories:");
      } finally {
        setLoading(false);
      }
    };
  
    fetchCategories();
  }, []);
  
  return (
    <>
      {/* <img className="bgTop" src="/assets/bgTop.png" alt="logo" /> */}
      <Navbar />
      <div className="container px-0">
        {/* Breadcrumb Section */}
        <div className="row mt-2 mb-3">
          <div className="col-10">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link id="link" to="/Home">
                  Home{" "}
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/Category">
                  Category
                </Link>
              </li>
            </ol>
          </div>
        </div>

        {/* Category List */}
        <div className="container">
          <div className="row">
            {loading ? (
              <div className="col-12 text-center">
                <img
                  src="/assets/bgload.gif"
                  alt="loading"
                  height="200px"
                />
              </div>
            ) : categories.length > 0 ? (
              categories.map((item, index) => (
                <div key={index} className="col-6">
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/Pro_Page?cat_id=${item.id}`}
                  >
                    <C_Card imgSrc={item.img} text={item.name} />
                  </Link>
                </div>
              ))
            ) : (
              <div className="col-12 text-center">No categories available.</div>
            )}
          </div>
        </div>
      </div>
      <div style={{ height: 24 }}>&nbsp;</div>
      <Toolbar />
    </>
  );
}

export default Category;
