import { Link, useLocation, useNavigate } from "react-router-dom";
import "./ProductDetail.css";
import Navbar from "../Navbar/Navbar";
import Toolbar from "../ToolBar/Toolbar";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { readFromDB,createInDB } from "../../indexedDB";
import { LiaCartPlusSolid } from "react-icons/lia";

function Details() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = parseInt(queryParams.get("product_id"));
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(""); // State for hero image
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // Fetch products from IndexedDB
        const storedProducts = await readFromDB("Products", "products");
        if (Array.isArray(storedProducts)) {
          setProducts(storedProducts);
        }
      } catch (error) {
        console.error("Error fetching products from IndexedDB:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const product = products.find((p) => p.id == productId);
  const images = Array.isArray(product?.img) ? product.img : []; // Ensure `img` is an array

  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0]); // Set initial hero image
    }
  }, [images]);
  

  const handleAddToCart = async (pro_id) => {
    try {
      const user = await readFromDB("UserStore", "user"); // Fetch user details from IndexedDB
      const existingCart = await readFromDB("Cart", "cart");
      const existingQty = await readFromDB("Cart", "qty");
      if (user) {
        setIsLoggedIn(true);

        let cartToUpdate = existingCart == null ? [] : existingCart;
        let qtyToUpdate =
          existingQty == null
            ? []
            : Array.isArray(existingQty)
            ? existingQty
            : Object.values(existingQty);

        let isProductInCart = false;
        cartToUpdate.forEach((element, index) => {
          if (element == pro_id) {
            toast.error("Product already exists in the cart!");
            isProductInCart = true;
          }
        });
        if (!isProductInCart) {
          //ok state
          let productToAdd = false;
          products.forEach((element, index) => {
            if (element.id == pro_id) {
              productToAdd = true;
            }
          });
          if (!productToAdd) {
            toast.error(`Product not found!`);
          } else {
            cartToUpdate = [...cartToUpdate, pro_id]; // Add product to cart
            qtyToUpdate = [...qtyToUpdate, 1];
            await createInDB("Cart", "cart", cartToUpdate);
            await createInDB("Cart", "qty", qtyToUpdate);
            toast.success("Product added to cart successfully!");
          }
        }
      } else {
        toast.error("Please login first!");
        navigate("/Login");
      }
    } catch (error) {
      toast.error("Failed to add product to cart.");
    }
  };


  if (loading) {
    return (
      <div className="text-center">
        <h3>Loading product...</h3>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="text-center">
        <h3>Product not found</h3>
      </div>
    );
  }

  return (
    <>
      {/* <img className="bgTop" src="/assets/bgTop.png" alt="logo" /> */}
      <Navbar />
      <div className="container">
        <div className="row d-flex align-items-center justify-content-between Details mt-2">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link id="link" to="/Home">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link id="link" to="/Pro_Page">
                Products
              </Link>
            </li>
          </ol>
        </div>

        <div className="d-flex justify-content-center PN">{product.name}</div>

        <div className="ProductD d-flex justify-content-center align-items-center ">
          <img id="HeroImg" src={`${selectedImage}`} alt={product.name} />
        </div>

        <div className="sub-image d-flex">
          {images.map((img, index) => (
            <img
              key={`image-${index}`}
              src={img} // Use the full URL from the `img` array
              alt={`Sub product ${index + 1}`}
              className="me-2"
              loading="lazy"

              onClick={() => setSelectedImage(img)}
            />
          ))}
        </div>
        <div className="PP d-flex justify-content-between">
          <p id="quantity">{product.unit_name}</p>
          <p id="price">{product.price} Rs</p>
        </div>

        <div className="PO d-flex align-items-center mb-3">
          <button
            className="jello-horizontal w-50"
            style={{ textDecoration: "none" }}
            onClick={() => handleAddToCart(parseInt(product.id))}>
            Add to Cart
          </button>
        </div>

        {product.description && (
          <div className="PC mt-4">
            <p id="CN">Description</p>
            <p id="CD">{product.description}</p>
          </div>
        )}
        {products
          .filter(
            (item) =>
              item.cat_id == product.cat_id && item.id != product.id
          )
          .length > 0 ? (
          <>
            <p id="RN">You can also check these items:</p>
            {products
              .filter(
                (item) =>
                  item.cat_id == product.cat_id && item.id != product.id
              )
              .map((item, index) => (
                <div className="row RP" key={index}>
                  <div className="col-4"           
                  onClick={() => { window.scrollTo(0, 0); navigate(`/Details?product_id=${item.id}`); }}
                  >
                    <img
                      className="RPI"
                      src={item.img[0]} // Access the first image directly
                      alt={`${item.name} image`}
                      loading="lazy"

                   />
              </div>
              <div className="col-6 d-flex flex-column justify-content-center"           
              onClick={() => { window.scrollTo(0, 0); navigate(`/Details?product_id=${item.id}`); }}
              >
                <div className="RPN">{item.name}</div>
                <div className="RPU">{item.unit_name}</div>

                <div className="RPP">
                  <strong>{item.price} PKR</strong>
                </div>
              </div>
              <div className="col-2 d-flex align-items-center">
                <button             
                onClick={() => handleAddToCart(parseInt(item.id))}  className="RB mb-3 fs-1"><LiaCartPlusSolid /></button>
              </div>
              <hr className="w-100" />
            </div>
          ))}
          </>
        ) : (
          <div className="text-center mt-4">
               <h4>No related items found.</h4>
          </div>
        )}
      </div>
      <div style={{ height: "60px" }}>&nbsp;</div>
      <Toolbar />
    </>
  );
}

export default Details;
