export const APIProductPickup = async (rider_id,order_id,product_id,callback) => {
    const myHeaders = new Headers();
    myHeaders.append("app", "grocy");
    myHeaders.append("callsign", "rider");
    //myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    const urlencoded = new URLSearchParams();
    urlencoded.append("picked_status", true);
    urlencoded.append("order_id", order_id);
    urlencoded.append("rider_id", rider_id);
    urlencoded.append("product_id", product_id);
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };
    
    const response = await fetch("https://api.fourelementx.com", requestOptions)
      .then(response => response.json())
      .catch(error => {
        console.error(error);
        callback (error);
      });
      callback (response);
  };