import SNavbar from "../SNavbar/SNavbar";
import { useNavigate } from "react-router-dom";
import { TbArrowBack } from "react-icons/tb";
import { Link } from "react-router-dom";
import { MdDeliveryDining } from "react-icons/md";
import { useEffect, useState } from "react";
import { readFromDB, createInDB } from "../../../indexedDB";
import { APISDailyHistory } from "../../../api/Seller/SDailyHistory";
import { toast } from "react-hot-toast";

import "./SDailyHistory.css";

function SDailyHistory() {
  const [User, setUser] = useState([]);
  const [order, setOrder] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await readFromDB("UserStore", "user");
      if (user && user.status == "seller") {
        setUser(user);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchDailyHistory = async (callback) => {
      const user = await readFromDB("UserStore", "user");
      if (user) {
        try {
          await APISDailyHistory(user.id, callback);
        } catch (error) {
          //          console.log(error);
        }
      }
    };

    const callback = (response) => {
      if (response?.message) {
        console.log(response.message);
        const ordersArray = Array.isArray(response.message)
          ? response.message
          : [];
        setOrder(ordersArray);
        createInDB("sDHistoryOrders", "SDHO", ordersArray).catch((error) =>
          console.log(error)
        );
      } else {
        //        toast.error(response.message);
      }
    };

    fetchDailyHistory(callback);
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <SNavbar />
      <div className="order-header d-flex align-items-center mb-3">
        <Link to="/SDashboard" className="text-decoration-none text-black">
          <TbArrowBack style={{ fontSize: "25px" }} />
          Today History
        </Link>
      </div>

      <div className="container">
        {order && order.length > 0 ? (
          order.map((item, index) => (
            <>
              <div
                key={index}
                className="order-card row align-items-center mt-1"
                onClick={() =>
                  navigate(`/SHODetails?uid=${item.seller_order.uid}`)
                }
              >
                <div className="order-icon col-2 d-flex align-items-center justify-content-center">
                  <MdDeliveryDining style={{ width: "60px", height: "60px" }} />
                </div>
                <div className="order-details col-6 d-flex flex-column">
                  <div className="order-number">
                    Order No: {item.seller_order.uid}
                  </div>
                  <div className="order-area">
                    Status: {item.seller_order.status}
                  </div>
                </div>
                <div className="order-price col-4 d-flex flex-column align-items-end">
                  <div className="order-date">Qty: {item.product.qty}</div>
                </div>
              </div>
              <hr />
            </>
          ))
        ) : (
          <div className="no-orders-container text-center mt-5">
            <div className="no-orders-card mx-auto">
              <MdDeliveryDining className="no-orders-icon" />
              <h3 className="no-orders-heading">No Orders Yet</h3>
              <p className="no-orders-text">
                It looks like you haven't placed any orders. Start exploring our
                products and make your first purchase today!
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default SDailyHistory;
