import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./OTP.css";
import { useRef, useEffect, useState } from "react";
import { APIForgetPassword } from "../../../api/ForgetPassword";
import { APIotp } from "../../../api/OTP";
import toast, { Toaster } from "react-hot-toast";
import { readFromDB } from "../../../indexedDB"; // Import IndexedDB utility
import { TbArrowBack } from "react-icons/tb"
function OTP() {
  const navigate = useNavigate();
  const location = useLocation();
  let toPage = location.state.toPage || false; // Safely retrieve toPage
  const [token, setToken] = useState("token");
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  let OTPref = useRef();
  let email = location.state.email || "";
  const [newEmail, setNewEmail] = useState(email);
  useEffect(() => {
    const user = readFromDB("UserStore", "user"); // Fetch user details from IndexedDB
    if (user) {
      setToken(user.token);
    } else {
      setToken(user.email);
    }
  }, []);

  useEffect(() => {
    APIForgetPassword(email, toPage).then((response) => {
      if(response.message) {
        setNewEmail(response.email);
        toast.success(response.message); 
        const otpd = document.getElementById("OTPdefault");
        otpd.style.display = "none";
        const otph = document.getElementById("OTPhidden");
        otph.style.display = "block";
      }else{
        navigate("/Login");
        toast.error(response.error);
      }
    });
  }, [email, toPage]);

  const handleOTPSubmit = () => {
    setIsLoading(true);
    toast.loading("Verifying OTP...", { id: "otp-loading", duration: 2000 });

    APIotp(newEmail, OTPref.current.value, token, toPage)
      .then((res) => {
        setIsLoading(false);
        toast.dismiss("otp-loading"); // Dismiss loading toast

        if (res.message) {
          toast.success("OTP verified successfully!");
          toPage = true;
        }

        if (res.redirect) {
          document.cookie = `email=${email}; path=/; `;
          navigate(`/${res.redirect}`);
        } else {
          toast.error(res.error || "An error occurred. Please try again."+res.error);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.dismiss("otp-loading");
        toast.error(err.message || "Failed to verify OTP.");
      });
  };

  return (
    <>
    <div className="container-fluid mt-2" style={{ fontSize: "20px" }}>
        <Link to={"/Login"} className="text-decoration-none text-black">
          <TbArrowBack style={{ fontSize: "25px" }}/> back to Login
        </Link>
      </div>
      <div className="container-fluid px-0">
        <div className="d-flex align-items-center justify-content-center">
        <img
            src="/assets/logo.png"
            alt="logo"
            className="LOGO"
          />
        </div>
          <h3 id="OTPdefault" className="mt-4 text-center">
            We Are Sending OTP on your email
          </h3>
          <div className="container-fluid mt-4" id="OTPhidden" style={{ display: "none" }}>
            <h2>Enter OTP sent to:</h2>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <FontAwesomeIcon icon={faKey} />
                </span>

                <input
                  type="text"
                  placeholder="Enter OTP"
                  className="form-control shadow-none"
                  ref={OTPref}
                  required
                />
              </div>
              <p>Your Email: {newEmail}</p> {/* Display the email */}

              <button
                type="button"
                className="Submit mt-3"
                onClick={handleOTPSubmit}
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? "Verifying..." : "Next"}
              </button>
            <div className="d-flex align-items-center mt-3">
              <button className="OTPbtn"
              onClick={() => window.location.reload()}
              >Resend OTP</button>
            </div>
          </div>
      </div>
      <div style={{ height: "60px" }}></div>
      
    </>
  );
}

export default OTP;
