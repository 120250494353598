export const APISROrder = async (seller_id,callback) => {
    const myHeaders = new Headers();
    myHeaders.append("app", "grocy");
    myHeaders.append("callsign", "seller");
    // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
    const urlencoded = new URLSearchParams();
    
    urlencoded.append("all_order", true);
    urlencoded.append("seller_id", seller_id);
    urlencoded.append("head","ready");

  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };
  
    const response = await fetch("https://api.fourelementx.com", requestOptions)
      .then((response) => response?.json())
      .then(result => {
        callback (result)
  })
      .catch(error => error);
  
      callback (response);
  };