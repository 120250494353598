import SNavbar from "../SNavbar/SNavbar";
import { useNavigate } from "react-router-dom";
import { TbArrowBack } from "react-icons/tb";
import { Link } from "react-router-dom";
import { MdDeliveryDining } from "react-icons/md";
import { APISAllOrders } from "../../../api/Seller/SAllOrders.js";
import { useEffect, useState } from "react";
import { readFromDB, createInDB } from "../../../indexedDB";
import { toast } from "react-hot-toast";

import "./SAllOrders.css";

function SAllOrders() {
  const [User, setUser] = useState([]);
  const [order, setOrders] = useState([]);

  useEffect(() => {
    const fetchUserAndOrders = async (callback) => {
      const user = await readFromDB("UserStore", "user");
      if (user && user.status == "seller") {
        setUser(user);
        try {
          await APISAllOrders(user.id, callback);
        } catch (error) {
          console.log(error);
        }
      }
    };

    const callback = (result) => {
      if (result && result.message) {
        const ordersArray = Array.isArray(result.message) ? result.message : [];
        setOrders(ordersArray);
        console.log("order", order);
        createInDB("sAllOrders", "SAO", ordersArray).catch((error) => console.log(error));
      } else {
//        toast.error(result.message);
      }
    };

    fetchUserAndOrders(callback);
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <SNavbar />
      <div className="order-header d-flex align-items-center mb-3">
        <Link to="/SDashboard" className="text-decoration-none text-black">
          <TbArrowBack style={{ fontSize: "25px" }} /> All Orders
        </Link>
      </div>

      <div className="container">
        {order && order.length > 0 ? (
          order.map((item, index) => (
            <>
            <div key={index} className="order-card row align-items-center mt-1"  onClick={() => navigate(`/SOrderDetails?uid=${item.seller_order.uid}`)}>
              <div className="order-icon col-2 d-flex align-items-center justify-content-center">
                <MdDeliveryDining style={{ width: "60px", height: "60px" }} />
              </div>
              <div className="order-details col-6 d-flex flex-column">
                <div className="order-number">Order No: {item.seller_order.uid}</div>
                <div className="order-area">Status: {item.seller_order.status}</div>
              </div>
              <div className="order-price col-4 d-flex flex-column align-items-end">
                <div className="order-date">Qty: {item.product.qty}</div>
              </div>
            </div>
            <hr/>
            </>
            
          ))
        ) : (
          <div className="no-orders-container text-center mt-5">
            <div className="no-orders-card mx-auto">
              <MdDeliveryDining className="no-orders-icon" />
              <h3 className="no-orders-heading">No Orders Yet</h3>
              <p className="no-orders-text">
                It looks like you haven't placed any orders. Start exploring our products and make your first purchase today!
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default SAllOrders;

