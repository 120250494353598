export const APIUpdate = async (u_id, phno) => {
    const myHeaders = new Headers();
    myHeaders.append("app", "grocy");
    myHeaders.append("callsign", "profile");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
    const urlencoded = new URLSearchParams();
    urlencoded.append("updateData", true);
    urlencoded.append("user_id", u_id);
    urlencoded.append("phone", phno);
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };
  
    const response = await fetch("https://api.fourelementx.com", requestOptions)
      .then((response) => response?.json())
      .then(result => {
      return result
  })
      .catch(error => error);
  
    return response;
  };
  export function updateImg(u_id, fileInput,fun) {

    let formData = new FormData();
    formData.append("updateImg", true);
    formData.append("user_id", u_id);
    formData.append("img", fileInput);

    apiImage("https://api.fourelementx.com", formData, data => fun(data) );
  }

  function apiImage(url, formData, callback) {
    const myHeaders = new Headers();
    myHeaders.append("app", "grocy");
    myHeaders.append("callsign", "profile");

    const settings = {
      method: "POST",
      headers: myHeaders,
      body: formData,
    };

    fetch(url, settings)
      .then((response) => response.json())
      .then((data) => callback(data))
      .catch((error) => console.error("Error:", error));
    }
