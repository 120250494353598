import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { readFromDB } from "../../../indexedDB";
import { TbArrowBack } from "react-icons/tb";
import RNavbar from "../Navbar/RNavbar";
import "./RPendingOrders.css";
import { APIOnWay } from "../../../api/OnWay";
import { APIProductPickup } from "../../../api/ProductPickUp";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function RPODetails() {
  const [orderDetail, setOrderDetail] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("order_id");
  const [checkedProducts, setCheckedProducts] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await readFromDB("rp_orders", "orders");
        const orderData = data.find(
          (order) => order.order_info.order_id == orderId
        );
        setOrderDetail(orderData);
      } catch (error) {
        toast.error("Error in fetching data:");
      }
    };
    fetchData();
  }, [orderId]);

  const handleCheckboxChange = async (productId) => {
    setCheckedProducts((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
    try {
      const User = await readFromDB("UserStore", "user");
      await APIProductPickup(
        User.id,
        orderDetail.order_info.order_id,
        [productId],
        (data) => {
          if (data.message) {
            toast.success(data.message);
          } else {
            setCheckedProducts((prev) => ({ ...prev, [productId]: false }));

            toast.error(data.error);
          }
        }
      );
    } catch (error) {
      toast.error("Error in updating product status:");
    }
  };

  const handleOnWayClick = async () => {
    const User = await readFromDB("UserStore", "user");
    try {
      await APIOnWay(User.id, orderDetail.order_info.order_id, (data) => {
        if (data.message) {
          toast.success(data.message);
        } else {
          toast.error(data.error);
        }
      });
    } catch (error) {
      //      toast.error("Error in updating status:");
    }
  };

  const handleUploadBilling = async () => {
    navigate(`/UploadRecipt?order_id=${orderDetail.order_info.order_id}`);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <RNavbar />
      <div
        className="RPODetails mt-4 p-3 border rounded"
        style={{ maxWidth: "600px", backgroundColor: "#f9f9f9" }}
      >
        {/* Back Button */}
        <div className="order-header d-flex align-items-center mb-4">
          <Link
            to="/RPendingOrders"
            className="text-decoration-none text-black"
          >
            <TbArrowBack style={{ fontSize: "20px" }} /> Pending Orders
          </Link>
        </div>

        {/* Receipt Header */}
        <h5 className="text-center mb-3">Order Receipt</h5>
        <hr />

        {/* Order Information */}
        <div className="mb-3">
          <p>
            <strong>Order ID:</strong>{" "}
            {orderDetail?.order_info?.order_id || "N/A"}
          </p>
          <div className="row mb-1">
            <div className="col-6">
              <p>
                <strong>Username:</strong> {orderDetail?.user_details?.username}
              </p>
            </div>
            <div className="col-6">
              <p className="mb-1">
                <strong>Phone:</strong> {orderDetail?.order_info?.phone}
              </p>
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-6">
              <p>
                <strong>Total:</strong>{" "}
                {parseInt(orderDetail?.order_info?.total) - 100}
              </p>
            </div>
            <div className="col-6">
              <p>
                <strong>Delivery:</strong>{" "}
                {orderDetail?.order_info?.delivery_charges}
              </p>
            </div>
          </div>
          <p className="mb-1">
            <strong>SubTotal:</strong>{" "}
            {parseInt(orderDetail?.order_info?.total)}
          </p>
          <p className="mb-1">
            <strong>Address:</strong> {orderDetail?.order_info?.address}
          </p>
        </div>

        {/* Products Table */}
        <table className="table  text-center  table-text">
          <thead className="table-light">
            <tr>
              <th>Product Name</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {orderDetail?.products?.map((product) => (
              <tr key={product.id}>
                <td>{product.name}</td>
                <td>
                  {product.unit_id} x{product.qty}
                </td>
                <td>{product.price * product.qty}</td>
                <td>
                  {product.order_pro_details.status === "ready" ? (
                    <input
                      type="checkbox"
                      checked={!!checkedProducts[product.id]}
                      onChange={() => handleCheckboxChange(product.id)}
                    />
                  ) : (
                    <span>{product.order_pro_details.status}</span>
                  )}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={3} className="text-end">
                <strong>Total Products:</strong>
              </td>
              <td>
                <strong>
                  {orderDetail?.products?.reduce(
                    (acc, curr) => parseInt(acc) + parseInt(curr.qty),
                    0
                  )}
                </strong>
              </td>
            </tr>
            <tr>
              <td colSpan={3} className="text-end">
                <strong>Total:</strong>
              </td>
              <td>
                <strong>
                  {orderDetail?.products?.reduce(
                    (acc, curr) => acc + curr.price * curr.qty,
                    0
                  )}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>

        {/* Order Status Section */}
        <div className="mt-3">
          <p className="mb-1">
            <strong>Order Status:</strong>{" "}
            {orderDetail?.order_info?.status || "N/A"}
          </p>
          {orderDetail?.order_info?.cancel_time && (
            <p className="mb-1">
              <strong>Cancel Time:</strong>{" "}
              {orderDetail?.order_info?.cancel_time}
            </p>
          )}
          {orderDetail?.order_info?.completed_time && (
            <p className="mb-1">
              <strong>Completed Time:</strong>{" "}
              {orderDetail?.order_info?.completed_time}
            </p>
          )}
          {orderDetail?.order_info?.ex_time && (
            <p className="mb-1">
              <strong>Expected Time:</strong> {orderDetail?.order_info?.ex_time}
            </p>
          )}
          {orderDetail?.order_info?.ex_date && (
            <p className="mb-1">
              <strong>Expected Date:</strong> {orderDetail?.order_info?.ex_date}
            </p>
          )}
          {orderDetail?.order_info?.process_time && (
            <p className="mb-1">
              <strong>Process Time:</strong>{" "}
              {orderDetail?.order_info?.process_time}
            </p>
          )}
          {orderDetail?.order_info?.onway_time && (
            <p className="mb-1">
              <strong>Onway Time:</strong> {orderDetail?.order_info?.onway_time}
            </p>
          )}
        </div>

        {/* <p className="text-center mb-0" style={{ fontSize: "12px" }}>Thank you for your order!</p> */}
      </div>
      <div className="d-flex justify-content-center mt-4">
        {orderDetail?.order_info?.status != "on way" ? (
          <button
            className="OnWay"
            onClick={() => {
              handleOnWayClick();
            }}
          >
            Put Order onway
          </button>
        ) : (
          <button
            className="billing"
            onClick={() => {
              handleUploadBilling();
            }}
          >
            Upload billing details
          </button>
        )}
      </div>
    </>
  );
}

export default RPODetails;
