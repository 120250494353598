import RNavbar from "../Navbar/RNavbar";
import { useNavigate } from "react-router-dom";
import { TbArrowBack } from "react-icons/tb";
import { Link } from "react-router-dom";
import { MdDeliveryDining } from "react-icons/md";
import { APIRAllOrders } from "../../../api/RAllOrders.js";
import { APIRAcceptOrders } from "../../../api/RAcceptOrder.js";
import { useEffect, useState } from "react";
import { readFromDB } from "../../../indexedDB";
import { toast } from "react-hot-toast";

import "./RAllOrders.css";

function RAllOrders() {
  const [User, setUser] = useState([]);
  const [order, setOrder] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await readFromDB("UserStore", "user");
      if (user && user.status == "rider") {
        setUser(user);
      } else {
        navigate("/Login");
      }
    };
    fetchUser();
  }, []);

  const fetchOrders = async () => {
    const user = await readFromDB("UserStore", "user");
    try {
      const data = await APIRAllOrders(User?.id);
      setOrder(data);
      console.log(data);
    } catch (error) {
      toast.error("Error in fetchOrders");
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [User]);
  function handleClick(id) {
    const acceptOrder = async () => {
      if (User.id && id) {
        const result = await APIRAcceptOrders(User.id, id);
        toast.success(result.message);
        fetchOrders();
      } else {
        toast.error("User ID or Order ID is missing");
      }
    };
    acceptOrder();
  }
  const navigate = useNavigate();

  return (
    <>
      <RNavbar />
      <div className="order-header d-flex align-items-center mb-3">
        <Link to="/RDashboard" className="text-decoration-none text-black">
          <TbArrowBack style={{ fontSize: "25px" }} /> All Orders
        </Link>
      </div>

      <div className="container">
        {order.length > 0 ? (
          order.map((item) => (
            <>
              <div
                className="order-card row align-items-center"
                key={item.order_id}
              >
                {/* Icon */}
                <div className="order-icon col-2 d-flex align-items-center justify-content-center">
                  <MdDeliveryDining style={{ width: "60px", height: "60px" }} />
                </div>

                {/* Order Details */}
                <div className="order-details col-6 d-flex flex-column">
                  <div className="order-number">Order No: {item.order_id}</div>
                  <div className="order-area">Area: {item.address}</div>
                </div>

                {/* Order Price */}
                <div className="order-price col-4 d-flex flex-column align-items-end">
                  <div className="order-amount">Rs. {item.total}</div>
                  <div className="order-date">items: {item.total_qty}</div>
                  <button
                    className="btn btn-accept mt-2"
                    onClick={() => handleClick(item.order_id)}
                  >
                    Accept
                  </button>
                </div>
              </div>
              <hr />
            </>
          ))
        ) : (
          <div className="no-orders-container text-center mt-5">
            <div className="no-orders-card mx-auto">
              <MdDeliveryDining className="no-orders-icon" />
              <h3 className="no-orders-heading">No Orders Yet</h3>
              <p className="no-orders-text">
                It looks like you haven't placed any orders. Start exploring our
                products and make your first purchase today!
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default RAllOrders;
