export const APIPlaceOrder = async (uid, pro, qty, address,area, pho, date, timeSlot, total) => {
    const myHeaders = new Headers();
    myHeaders.append("app", "grocy");
    myHeaders.append("callsign", "order");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    const urlencoded = new URLSearchParams();
    urlencoded.append("placeOrder", "true");
    urlencoded.append("user_id", uid);
    urlencoded.append("pro_id", pro);
    urlencoded.append("pro_qty", qty);
    urlencoded.append("note", "");
    urlencoded.append("date", date);
    urlencoded.append("time", timeSlot);
    urlencoded.append("phone", pho);
    urlencoded.append("address", address);
    urlencoded.append("area_id", area);
    urlencoded.append("delivery", 100);
    urlencoded.append("total", total);
    
    

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };
    
    const response = await fetch("https://api.fourelementx.com", requestOptions)
      .then(response => response.json())
      .catch(error => {
        console.error(error);
        return error;
      });
    return response;
  };
  