export const APINewPass = async (login,password) => {
    const myHeaders = new Headers();
    myHeaders.append("app", "grocy");
    myHeaders.append("callsign", "passupdate");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
    const urlencoded = new URLSearchParams();
    urlencoded.append("login", login);
    urlencoded.append("password", password);
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };
  
    const response = await fetch("https://api.fourelementx.com", requestOptions)
      .then((response) => response?.json())
      .then(result => {
      return result
  })
      .catch(error => error);
  
    return response;
  };
  