export const EditAPIAddress = async (u_id,id,address,code,subcity) => {
    const myHeaders = new Headers();
    myHeaders.append("app", "grocy");
    myHeaders.append("callsign", "address");
    // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
    const urlencoded = new URLSearchParams();
    
    urlencoded.append("update", true);
    urlencoded.append("user_id", u_id);
    urlencoded.append("id", id);
    urlencoded.append("address", address);
    urlencoded.append("code", code);
    urlencoded.append("subcity", subcity);
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };
  
    const response = await fetch("https://api.fourelementx.com", requestOptions)
      .then((response) => response?.json())
      .then(result => {
      return result
  })
      .catch(error => error);
  
    return response;
  };