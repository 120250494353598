import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  return (
    <>
      <div className="btn-container d-flex justify-content-center align-items-center mt-5">
        <div style={{ "--a": 0 }} className="btn-sensor sensor-n"></div>
        <div style={{ "--a": 45 }} className="btn-sensor sensor-ne"></div>
        <div style={{ "--a": 90 }} className="btn-sensor sensor-e"></div>
        <div style={{ "--a": 135 }} className="btn-sensor sensor-se"></div>
        <div style={{ "--a": 180 }} className="btn-sensor sensor-s"></div>
        <div style={{ "--a": 225 }} className="btn-sensor sensor-sw"></div>
        <div style={{ "--a": 270 }} className="btn-sensor sensor-w"></div>
        <div style={{ "--a": 315 }} className="btn-sensor sensor-nw"></div>
        <button className="btn-button">
          <div className="btn-lid"></div>
          <div className="btn-pupil"></div>
        </button>
        <button className="btn-button">
          <div className="btn-lid"></div>
          <div className="btn-pupil"></div>
        </button>
      </div>

      <div style={{ textAlign: "center", marginTop: "30px" }}>
        <h1>404</h1>
        <h5>Page Not Found</h5>
        <Link to="/">Go back to Home</Link>
      </div>
    </>
  );
};

export default NotFound;
