import Navbar from "../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Toolbar from "../ToolBar/Toolbar";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { useEffect, useState, useRef } from "react";
import { readFromDB, createInDB } from "../../indexedDB";
import { APIAddress } from "../../api/getAddress";
import { faTrash } from "@fortawesome/free-solid-svg-icons"; // Import specific icons
import { APIDeleteAddress } from "../../api/AddressDelete";
import {APICity} from "../../api/City";
import {APISubCity} from "../../api/SubCity";
import {EditAPIAddress} from "../../api/EditAddress";
import { useNavigate } from "react-router-dom";
import "./MyAddress.css";

function MyAddress() {
  const [address, setAddress] = useState([]);
  const [user, setUser] = useState([]);
  const [editingAddress, setEditingAddress] = useState(null);
  const EditAddref = useRef(null);
  const EditidRef = useRef(null);
  const EditSelectedType = useRef(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    (async () => {
      const fetchedUser = await readFromDB("UserStore", "user");
      setUser(fetchedUser);
    })();
  }, []);

  useEffect(() => {
    const fetchAddresses = async () => {
      if (!user || !user.id) return;
      try {
        const Uaddress = await readFromDB("Address", "address");
        if(Array.isArray(Uaddress)){
        setAddress(Uaddress);
        const data = await APIAddress(user.id);
        await createInDB("Address", "address", data);
      }
          
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses();
  }, [user]);


  const handleCityChange = async (e) => {
    try {
      const cityId = e.target.value;
      const data = await APISubCity(cityId);
      const select = document.getElementById("EditSubCity");
      select.innerHTML = "";
      data.forEach((city) => {
        const option = document.createElement("option");
        option.value = city.id;
        option.textContent = city.name;
        select.appendChild(option);
      });
    } catch (error) {
      console.error("Error fetching subcities:", error);
    }
  }
  const  handleEditClick = async (address) => {
    EditAddref.current.value = address.address;
    EditSelectedType.current.value = address.code;
    EditidRef.current.value = address.id;
    let EditCity=address.city;
    let EditSubCity=address.subcity;
    try {
      const data = await new Promise((resolve, reject) => {
        APICity(resolve);
      });
      const select = document.getElementById("EditCity");
      select.innerHTML = "";
      data.forEach((city) => {
        const option = document.createElement("option");
        option.value = city.id;
        option.textContent = city.name;
        select.appendChild(option);
        if (city.id == EditCity) {
          select.value = city.id;
        }
      });
      
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
    try {
      const cityId = document.getElementById("EditCity").value;
      const data = await APISubCity(cityId);
      const select = document.getElementById("EditSubCity");
      select.innerHTML = "";
      data.forEach((city) => {
        const option = document.createElement("option");
        option.value = city.id;
        option.textContent = city.name;
        select.appendChild(option);
        if (city.id == EditSubCity) {
          select.value = city.id;
        }
      });
    } catch (error) {
      console.error("Error fetching subcities:", error);
    }
  }


  const handleDeleteAddress = async (addressId) => {
    try {
      const updatedAddresses = address.filter((item) => item.id !== addressId);

      await APIDeleteAddress(user.id, addressId);
      await createInDB("Address", "address", updatedAddresses);

      // Update the state
      setAddress(updatedAddresses);

      toast.success("Address deleted successfully!");
    } catch (error) {
      console.error("Error deleting address:", error);
      toast.error("Error deleting address!");
    }
  };


  const EditAddress = async () => {
    const type = EditSelectedType.current?.value;
    if (!type || type.trim() == "") {
      toast.error("Please select an address type.");
      return;
    }

    const add = EditAddref.current?.value;
    if (!add || add.trim() == "") {
      toast.error("Address field cannot be empty!");
      return;
    }

    const SubCityId = document.getElementById("EditSubCity").value;
    const id = document.getElementById("Editid").value;

    try {
      const data = await EditAPIAddress(user.id, id, add, type, SubCityId);
      if (data.message) {
        toast.success(data.message);
      } else {
        toast.error(data.error || "An error occurred while saving the address.");
      }
      const updatedAddresses = address.map((addr) => (addr.id === id ? { ...addr, address: add, code: type, subcity: SubCityId } : addr));
      const cancelButton = document.getElementById("cancelButtont");
      if (cancelButton) {
        cancelButton.click();
      }
      await createInDB("Address", "address", updatedAddresses);
      setAddress(updatedAddresses);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };
  return (
    <>
      <Navbar />
      <div className="container-fluid px-0 mt-2 mb-2">
        <div className="row ">
          <div className="col-10">
            <ol className="breadcrumb ">
              <li className="breadcrumb-item">
                <Link id="link" to="/More">
                  More
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link id="link" to="/MyAddress">
                  My Address
                </Link>
              </li>
            </ol>
          </div>
        </div>

        {address && address.length > 0 ? (
          address.map((item, index) => (
            <div key={index}>
              <div className="AddressType">{item.code}</div>
              <div className="row CartP align-items-center">
                <div className="col-8 d-flex flex-column justify-content-center">
                  <div className="CartPOne">{item.address}</div>
                </div>

                <div className="col-4 d-flex align-items-center gap-2 Addressbtn">
                  <button
                    type="button"
                    className="btnD"
                    data-toggle="modal"
                    data-target="#editModal"
                    onClick={() => {
                      handleEditClick(item)
                    }}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </button>
                  <button
                    type="button"
                    className="btnD bg-danger"
                    onClick={() => handleDeleteAddress(item.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
              <hr />
            </div>
          ))
        ) : (
          <div className="CartPOne">No Address Found</div>
        )}

        {/* Add New Address */}
        {address.length < 3 && (
          <div>
            <Link className="OrderButton" to="/AddNewAddress">
              <button>Add New Address</button>
            </Link>
          </div>
        )}
      </div>

      {/* Edit Address Modal */}
      <div
        className="modal fade"
        id="editModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Address
              </h5>
              <button
                type="button"
                id="cancelButtont"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setEditingAddress(null)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="col-form-label fs-5"><small>Title:</small></label>
                <input
                  className="form-control form-control-sm shadow-none"
                  id="message-text"
                  ref={EditSelectedType}
                />
              </div>

              <div className="form-group">
                <label className="col-form-label fs-5"> <small>City:</small></label>
                <select className="form-select form-select-sm shadow-none my-1" id="EditCity" 
                onChange={(e) => handleCityChange(e)}
                >                
                  <option value="">Select City</option>
                </select>
              </div>

              <div className="form-group">
              <label className="col-form-label fs-5"><small>Sub City:</small></label>
                <select className="form-select form-select-sm shadow-none my-1" id="EditSubCity">
                  <option value="">Select Sub City</option>
                </select>
              </div>

              <div className="form-group">
                <label className="col-form-label fs-5"><small>Address:</small></label>
                <input
                  className="form-control form-control-sm  shadow-none"
                  id="message-text"
                  ref={EditAddref}
                />
                <input
                id="Editid"
                  type="hidden"
                  ref={EditidRef}
                />
              </div>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                id="cancelButton"
                onClick={() => setEditingAddress(null)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => EditAddress()}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>


      <Toolbar />
      <div style={{ height: "60px" }}></div>
    </>
  );
}

export default MyAddress;
